import { format } from 'date-fns';

import { SendEmailMessage } from '../../common/chat/types';
import { emailAddressToString, formatEmailStatusGuess } from '../../common/emails';

export type SendEmailProps = {
  message: SendEmailMessage;
};

export function SendEmail(props: SendEmailProps) {
  const { email } = props.message;

  const timestamp = email.received ?? email.sent ?? email.created;
  const userAddress = undefined;

  return (
    <div className="space-y-2 text-gray-300">
      <h2 className="text-base font-bold text-green-500">Email Sent!</h2>
      <div className="bg-gray-800 rounded-lg shadow-md py-2 px-4 border-4 border-double border-green-500">
        <h3 className="text-base font-semibold">{email.subject}</h3>
        <div>
          <p className="text-gray-400">From: {emailAddressToString(email.from_, userAddress)}</p>
          <p className="text-gray-400">
            To: {email.to.map((a) => emailAddressToString(a)).join(', ')}
          </p>
          {email.cc && email.cc.length > 0 && (
            <p className="text-gray-400">
              CC: {email.cc.map((a) => emailAddressToString(a)).join(', ')}
            </p>
          )}
          {email.bcc && email.bcc.length > 0 && (
            <p className="text-gray-400">
              BCC: {email.bcc.map((a) => emailAddressToString(a)).join(', ')}
            </p>
          )}
          {/* <p className="text-gray-400">
            {formatEmailStatusGuess(email, user?.email ?? 'Unknown')}{' '}
            {timestamp
              ? 'at ' + format(timestamp, 'p') + ' on ' + format(timestamp, 'MMMM do, yyyy')
              : ''}
          </p> */}
        </div>
        {email.body.split('\n').map((line, index) =>
          line === '' ? (
            <br key={index} />
          ) : (
            <p key={index} className="text-white">
              {line}
            </p>
          ),
        )}
      </div>
    </div>
  );
}
