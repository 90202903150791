import { useContext, useState } from 'react';
import {
  AppLayout,
  ContentLayout,
  Header,
  Grid,
  Box,
  Link,
  SpaceBetween,
  Container,
} from '@cloudscape-design/components';

import { LocalNavigation, Breadcrumbs, LocalNavigationProps } from '../../navigation';
import { EmptyState, ErrorState, LoadingState } from '../../common/data/loading';
import { FrameContext } from '../../common/data/context';
import { NAV_WIDTH } from '../../common/window';

import { QuestionsTable } from './table';
import { QuestionsHelp } from './help';

function QuestionsContent({ setOpen }: { setOpen: (open: boolean) => void }) {
  const state = useContext(FrameContext).state;

  if (state.loading) {
    return <LoadingState />;
  }

  if (state.error) {
    return (
      <SpaceBetween direction="vertical" size="xxs">
        <EmptyState />
        <ErrorState error={state.error} onRetry={state.retry} />
      </SpaceBetween>
    );
  }

  return (
    <Grid gridDefinition={[{ colspan: { default: 12 } }]}>
      <QuestionsTable
        header={
          <Header
            description={
              <Box variant="p">
                Filter, Sort, and View COVID-19 Vaccine Opinion-Based Questions. What is an
                opinion-based question? See{' '}
                <Link variant="info" onFollow={() => setOpen(true)}>
                  Info
                </Link>
              </Box>
            }
            variant="h2"
          >
            Questions{' '}
            <Link variant="info" onFollow={() => setOpen(true)}>
              Info
            </Link>
          </Header>
        }
        questionThemeTextLookup={state.value!.questionThemeTextLookup}
        questions={state.value!.questionInfos}
      />
    </Grid>
  );
}

export default function QuestionsPage(props: LocalNavigationProps) {
  const [open, setOpen] = useState(false);

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Frames', href: '/frames' },
            { text: 'Questions', href: '/frames/questions' },
          ]}
        />
      }
      content={
        <ContentLayout
          header={
            <Container
              header={
                <Header
                  description="Opinion-Based Question Answering for Framing Discovery with the COVID-19 Vaccine on Twitter"
                  variant="h1"
                >
                  COVID-19 Vaccine Hesitancy Questions
                </Header>
              }
              disableContentPaddings
            />
          }
        >
          <QuestionsContent setOpen={setOpen} />
        </ContentLayout>
      }
      contentType="default"
      navigation={<LocalNavigation />}
      navigationOpen={props.navigationOpen}
      navigationWidth={NAV_WIDTH}
      tools={<QuestionsHelp />}
      toolsOpen={open}
      toolsWidth={500}
      onNavigationChange={(event) => props.setNavigationOpen(event.detail.open)}
      onToolsChange={(event) => setOpen(event.detail.open)}
    />
  );
}
