import { CalendarEventDeletedMessage } from '../../common/chat/types';
import { formatEventSpan } from '../../common/events';

export type CalendarEventDeletedProps = {
  message: CalendarEventDeletedMessage;
};

export function CalendarEventDeleted(props: CalendarEventDeletedProps) {
  const { event } = props.message;
  const { subject, location } = event;

  return (
    <div className="space-y-2 text-gray-300">
      <h2 className="text-base font-bold text-red-500">Event Deleted</h2>
      <div className="bg-gray-700 rounded-lg shadow-md py-2 px-4 border border-red-500">
        <h3 className="text-base font-semibold line-through">{subject}</h3>
        <p className="text-gray-400 line-through">{formatEventSpan(event)}</p>
        {location && <p className="event-location text-gray-400 line-through">{location}</p>}
        {/* {body && <p className="event-body text-gray-300 mt-1">{body}</p>} */}
        {/* TODO better way to display body and categories in click modal */}
        {/* {categories && categories.length > 0 && (
          <div className="event-categories mt-2 flex flex-wrap">
            {categories.map((category, index) => (
              <span
                key={`${props.message.id}-category-${index}`}
                className="mb-2 px-2 py-1 bg-blue-700 text-blue-200 rounded-full text-xs"
              >
                {category}
              </span>
            ))}
          </div>
        )} */}
      </div>
    </div>
  );
}
