import { readChatStream } from '../chat/streaming';
import {
  ChatSessionResponse,
  ChatMessagePoll,
  MessageRequest,
  ChatSession,
  ChatMessage,
  AudioMessageRequest,
  AudioMessageChatResponse,
} from '../chat/types';

import { StreamingError, handleFetchResponse } from './errors';
import axiosInstance, { apiUrl } from './axiosInstance';

export async function createQuestion(
  message: MessageRequest,
  setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>,
  token?: string,
) {
  const url = `${apiUrl}/questions`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(message),
  });

  if (!response.ok || response.body === null) {
    const error = await handleFetchResponse(response);
    throw error;
  }

  const reader = response.body.getReader();
  await readChatStream(reader, setMessages);
}

export async function createQuestionAudio(
  message: AudioMessageRequest,
  setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>,
  token?: string,
) {
  const url = `${apiUrl}/questions/audio`;
  const formData = new FormData();
  formData.append('audio', message.audio);
  formData.append('mime_type', message.mime_type);
  formData.append('location', message.location ?? 'default');
  formData.append('sample_rate', message.sample_rate.toString());

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
    body: formData,
  });

  if (!response.ok || response.body === null) {
    const error = await handleFetchResponse(response);
    throw error;
  }

  const reader = response.body.getReader();
  await readChatStream(reader, setMessages);
}
