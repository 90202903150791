import { useRef, useState, useEffect } from 'react';
import { WavStreamPlayer } from 'wavtools';

import { AudioMessage, TextMessage } from '../../common/chat/types';

function base64ToArrayBuffer(base64: string): ArrayBuffer {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
}

export type AudioMessagePlayerProps = {
  message: AudioMessage;
  loading?: boolean;
  index: number;
  length: number;
};

export default function AudioMessagePlayer(props: AudioMessagePlayerProps) {
  const sampleRate = 24000;
  const { audio } = props.message;
  const playerRef = useRef<WavStreamPlayer>();
  const [isConnected, setIsConnected] = useState(false);
  // const [currentTime, setCurrentTime] = useState(0);
  const timerRef = useRef<number>();

  // initialize the WavStreamPlayer when component mounts
  useEffect(() => {
    const initPlayer = async () => {
      playerRef.current = new WavStreamPlayer({ sampleRate });

      try {
        await playerRef.current.connect();
        setIsConnected(true);
      } catch (err) {
        console.error('Error connecting audio player:', err);
      }
    };

    initPlayer();

    // clean up timer on unmount
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [sampleRate]);

  // whenever a new chunk arrives, convert it and add to the player
  useEffect(() => {
    if (isConnected && audio && playerRef.current) {
      try {
        const arrayBuffer = base64ToArrayBuffer(audio);
        // the add16BitPCM method handles both ArrayBuffer and Int16Array
        playerRef.current.add16BitPCM(arrayBuffer);
      } catch (err) {
        console.error('Error processing audio chunk:', err);
      }
    }
  }, [audio, isConnected]);

  // set up a timer to query current playback time from the player
  useEffect(() => {
    if (isConnected && playerRef.current) {
      timerRef.current = window.setInterval(async () => {
        try {
          if (!playerRef.current) {
            return;
          }

          const offset = playerRef.current.getTrackSampleOffset();
          // currentTime in seconds (offset / sampleRate)
          // setCurrentTime(offset.currentTime);
        } catch (err) {
          console.error('Error getting playback time:', err);
        }
      }, 500); // update every 500ms
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isConnected]);

  // Example controls: here we simply offer a "stop" button that interrupts the stream.
  // You could expand this with play/pause as needed.
  const handleStop = async () => {
    if (playerRef.current) {
      playerRef.current.interrupt();
    }
  };

  return (
    <div>
      <div>
        <strong>Audio Response</strong>
      </div>
      <div>
        <button onClick={handleStop}>Stop</button>
      </div>
      <div>{/* Current Time: {currentTime.toFixed(2)} sec */}</div>
    </div>
  );
}
