import { useState, useEffect, createRef, useRef } from 'react';

import { useChat } from '../../common/chat/context';
import { ChatMessage } from '../../common/chat/types';
import { useQuestionApi } from '../../common/questions/api';

import { ChatTurn } from './ChatTurn';
import { ChatBar } from './ChatBar';
import { ErrorTurn } from './ErrorTurn';

export function ChatBox() {
  const chatContentRef = createRef<HTMLDivElement>();

  const prevChatScrollEnd = useRef<number>(0);

  const barRef = useRef<HTMLTextAreaElement | null>(null);

  const [messages, setMessages] = useState<ChatMessage[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ask, loading, error, askAudio } = useQuestionApi(setMessages);

  useEffect(() => {
    if (chatContentRef.current) {
      const currentScroll = chatContentRef.current.scrollTop;
      const height = chatContentRef.current.clientHeight;
      const scrollHeight = chatContentRef.current.scrollHeight;
      const scrollEnd = scrollHeight - height;
      const prevScrollEnd = !prevChatScrollEnd.current ? scrollEnd : prevChatScrollEnd.current;

      const scrollEndTolerance = 100;

      if (currentScroll !== scrollEnd) {
        // console.log(`scroll ${currentScroll} -> ${prevScrollEnd} ? (${currentScroll + scrollEndTolerance} >= ${prevScrollEnd} -> ${currentScroll + scrollEndTolerance >= prevScrollEnd})`);
        if (currentScroll + scrollEndTolerance >= prevScrollEnd) {
          chatContentRef.current.scrollTop = scrollEnd;
        }
      }
      prevChatScrollEnd.current = scrollEnd;
    }
  }, [messages, chatContentRef]);

  // useEffect(() => {
  //   if (barRef.current && !loading && !awaiting && !streaming) {
  //     barRef.current.focus();
  //   }
  // }, [loading, awaiting, messages, streaming, barRef]);

  return (
    <div className="flex flex-col flex-grow min-h-0">
      <div className="px-4 pb-2 pt-4 bg-transparent">
        <div className="mx-auto lg:max-w-6xl bg-transparent">
          <ChatBar addAudio={askAudio} addMessage={ask} loading={loading} textareaRef={barRef} />
        </div>
      </div>
      <div ref={chatContentRef} className="flex-grow overflow-y-auto overflow-x-hidden">
        <div className="flex flex-col px-4 space-y-2 mx-auto lg:max-w-6xl">
          {messages.map((item, index) => (
            <ChatTurn
              key={index}
              addMessage={ask}
              index={index}
              length={messages.length}
              loading={loading}
              message={item}
            />
          ))}
          {error && !loading && <ErrorTurn error={error} />}
        </div>
      </div>
    </div>
  );
}
