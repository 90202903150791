import { createRef, useState } from 'react';

import { MemoryDeletedMessage } from '../../common/chat/types';
import { Modal } from '../Modal';

import { MemoryIcon } from './MemoryIcon';

export type MemoryDeletedProps = {
  message: MemoryDeletedMessage;
};

export function MemoryDeleted(props: MemoryDeletedProps) {
  const { memory } = props.message;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const scrollRef = createRef<HTMLDivElement>();

  return (
    <div>
      <button className="outline-none" onClick={() => setIsModalOpen(true)}>
        <p className="text-gray-300 text-base flex items-center space-x-1">
          <MemoryIcon />
          <span>Memory Deleted</span>
        </p>
      </button>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div>
          <h2 className="text-lg font-bold px-4 pt-4 text-gray-300">Memory Deleted</h2>
          <p className="text-gray-400 mb-4 px-4 line-through">
            {`Created at ${new Date(memory.created_at + 'Z').toLocaleString()}`}
          </p>
        </div>
        <div
          ref={scrollRef}
          className="max-h-[80dvh] overflow-y-auto flex flex-col px-4 space-y-2 text-gray-300 pb-4"
        >
          {memory.content.split('\n').map((content, index) => (
            <p key={index} className="text-white line-through">
              {content}
            </p>
          ))}
        </div>
      </Modal>
    </div>
  );
}
