import { Box, Grid, Header, TextContent } from '@cloudscape-design/components';
import React from 'react';
import 'katex/dist/katex.min.css';
import TeX from '@matejmazur/react-katex';
import { Macro, String as StringNode, Node, Environment } from '@unified-latex/unified-latex-types';

import { Publication } from '../publications';

import { TexTable, createTable } from './table';
import { TexFigure, createFigure } from './figure';
import { ContentSections, ContentSection, ContentBlock } from './content';
import { visitNode, visitMacro, visitMathNode, visitNodeChildren } from './parsing';

export function buildContent(
  nodes: Node[],
  path: string,
  citations: Publication[],
  citationNumber?: { [key: string]: string },
) {
  const content: ContentSections = new ContentSections({
    citations,
    citationNumber,
  });

  for (const node of nodes) {
    if (node.type === 'macro') {
      const macro = node as Macro;

      if (macro.content === 'title') {
        // handle title
        const children = macro.args![macro.args!.length - 1].content;
        const titleContent = visitNodeChildren(children, content);
        content.setTitle(titleContent);
      } else if (macro.content === 'author') {
        const children = macro.args![macro.args!.length - 1].content;
        const authorContent = visitNodeChildren(children, content);
        const authors = authorContent
          .join('')
          .split(',')
          .map((x) => x.trim());
        content.setAuthors(authors);
      } else if (macro.content === 'abstract') {
        // handle abstract
        const children = macro.args![macro.args!.length - 1].content;
        const abstractContent = visitNodeChildren(children, content);
        content.setAbstract(abstractContent);
      } else if (macro.content === 'name') {
        content.openAuthor = true;
      } else if (macro.content === 'affiliations' || macro.content === 'address') {
        content.openAffiliations = true;
      } else if (macro.content === 'section') {
        let mod = undefined;
        const modifiers = macro.args![0].content;

        if (modifiers.length > 0) {
          const modifier = modifiers[0];

          if (modifier.type === 'string') {
            mod = (modifier as StringNode).content;
          }
        }

        let secKey = `section-${content.sections.length + 1}`;
        let secNumber = `${content.sections.length + 1}`;

        if (content.openAppendix) {
          secKey = `appendix-${content.appendices.length + 1}`;
          secNumber = `${(content.appendices.length + 10).toString(36).toUpperCase()}`;
        }

        const children = macro.args![macro.args!.length - 1].content;
        const sectionContent = visitNodeChildren(children, content);
        const titleHeader = (
          <Header
            children={sectionContent}
            key={`${secKey}-header`}
            id={`${secKey}-header`}
            variant="h3"
          />
        );
        const sec = new ContentSection({
          header:
            mod === '*' ? (
              titleHeader
            ) : (
              <Grid
                key={`${secKey}-header-grid`}
                gridDefinition={[{ colspan: { default: 1 } }, { colspan: { default: 11 } }]}
              >
                <Header key={`${secKey}-number-header`} variant="h3">
                  {secNumber}
                </Header>
                {titleHeader}
              </Grid>
            ),
        });

        if (content.openAppendix) {
          content.pushAppendix(sec);
        } else {
          content.pushSection(sec);
        }
      } else if (macro.content === 'subsection') {
        const children = macro.args![macro.args!.length - 1].content;
        const subChildren = visitNodeChildren(children, content);
        content.pushBlock(
          new ContentBlock({
            type: 'block',
            contents: [
              <Box
                children={subChildren}
                key={`section-${content.sections.length}-subsection-${++content.nodeId}-header`}
                variant="h4"
              />,
            ],
          }),
        );
      } else if (macro.content === 'appendix') {
        content.openAppendix = true;
      } else if (macro.content === '\\' || macro.content === 'newline') {
        // make sure we don't have multiple line breaks in a row
        if (content.getContents().length > 0) {
          // end of paragraph
          content.pushBlock(
            new ContentBlock({
              contents: [],
              type: 'p',
            }),
          );
        }
      } else {
        visitMacro(macro, content.getContents(), content);
      }
    } else if (node.type === 'environment') {
      const environment = node as Environment;

      if (environment.env === 'abstract') {
        // handle abstract
        const children = environment.content;
        const abstractContent = visitNodeChildren(children, content);
        content.setAbstract(abstractContent);
      } else if (environment.env === 'table' || environment.env === 'table*') {
        // TODO consider parsing args for [ht] tags
        // TODO label will map to table-${this.tableId}
        const table = new TexTable({ tableId: content.tables.length + 1 });
        createTable(environment, table, content);
        content.tables.push(table);
        content.citationNumber[table.label] = `${table.tableId}`;

        content.pushBlock(
          new ContentBlock({
            contents: [table.render()],
            type: 'table',
          }),
        );
      } else if (environment.env === 'figure' || environment.env === 'figure*') {
        const figure = new TexFigure({ figureId: content.figures.length + 1 });
        createFigure(environment, figure, path, content);
        content.figures.push(figure);
        content.citationNumber[figure.label] = `${figure.figureId}`;

        // content.references[figure.label] = figure.elementKey;

        content.pushBlock(
          new ContentBlock({
            contents: [figure.render()],
            type: 'figure',
          }),
        );
      } else if (environment.env === 'enumerate' || environment.env === 'itemize') {
        const items: React.ReactNode[][] = [];
        const numbered = environment.env === 'enumerate';

        for (const item of environment.content) {
          if (item.type === 'parbreak') {
            continue;
          } else if (item.type === 'macro' && item.content === 'item') {
            const itemContent = visitNodeChildren(
              item.args![item.args!.length - 1].content,
              content,
            );
            items.push(itemContent);
          } else {
            console.error('Unknown enum item: ', item);
          }
        }
        content.pushBlock(
          new ContentBlock({
            contents: [
              numbered ? (
                <TextContent key={`list-${++content.nodeId}`}>
                  <ol
                    children={items.map((c) => {
                      return <li children={c} key={`list-li-${++content.nodeId}`} />;
                    })}
                    key={`list-ol-${++content.nodeId}`}
                  />
                </TextContent>
              ) : (
                <TextContent key={`list-${++content.nodeId}`}>
                  <ul
                    children={items.map((c) => {
                      return <li children={c} key={`list-li-${++content.nodeId}`} />;
                    })}
                    key={`list-ul-${++content.nodeId}`}
                  />
                </TextContent>
              ),
            ],
            type: 'list',
          }),
        );
      } else {
        console.error('UNKNOWN ENVIRONMENT', node);
      }
    } else if (node.type === 'parbreak') {
      if (node.position!.end.line - node.position!.start.line <= 2) {
        // treat single line break as space
        const lastContents = content.getContents();

        if (lastContents.length > 0 && lastContents[lastContents.length - 1] !== ' ') {
          content.pushContent(' ');
        }
      } else {
        // make sure we don't have multiple line breaks in a row
        if (content.getContents().length > 0) {
          // end of paragraph
          content.pushBlock(
            new ContentBlock({
              contents: [],
              type: 'p',
            }),
          );
        }
      }
    } else if (node.type === 'mathenv') {
      const mathEnv = node as Environment;
      const currentMath: React.ReactNode[] = [];

      for (const child of mathEnv.content) {
        visitMathNode(child, currentMath, content);
      }

      // type annotations are wrong for this type, so cast to proper node
      const env = (mathEnv.env as unknown as StringNode).content;

      if (env === 'equation' || env === 'multline') {
        let eqKey = `equation-${content.equations.length + 1}`;

        if (content.openLabel) {
          content.citationNumber[content.openLabel] = `${content.equations.length + 1}`;
          eqKey = content.openLabel;
          content.openLabel = undefined;
        }

        const equation = <TeX key={eqKey} id={eqKey} math={currentMath.join('')} block />;
        const equationContent = (
          <Grid
            key={`${eqKey}-grid`}
            className="equation"
            gridDefinition={[{ colspan: { default: 1 } }, { colspan: { default: 11 } }]}
          >
            <Box key={`${eqKey}-number`} variant="strong">{`(${
              content.equations.length + 1
            })`}</Box>
            {equation}
          </Grid>
        );

        content.pushBlock(
          new ContentBlock({
            contents: [equationContent],
            type: 'block',
          }),
        );
        content.equations.push(equationContent);
      } else {
        // not a numbered equation
        const mathEnv = (
          <TeX key={`mathenv-${++content.nodeId}`} math={currentMath.join('')} block />
        );
        content.pushBlock(
          new ContentBlock({
            contents: [mathEnv],
            type: 'block',
          }),
        );
      }
    } else {
      visitNode(node, content.getContents(), content);
    }
  }

  return content;
}
