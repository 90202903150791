import { parseISO, format } from 'date-fns';
import { createRef, useMemo, useState } from 'react';
import { Toggle } from '@cloudscape-design/components';

import { EmailMessage, EmailListMessage } from '../../common/chat/types';
import {
  emailAddressToString,
  emailAddressToStringShort,
  formatEmailStatus,
  formatEmailTimestamp,
  getEmailTimestamp,
  groupEmails,
} from '../../common/emails';
import { formatTitleRange } from '../../common/events';
import { Modal } from '../Modal';

import { EmailContent } from './EmailContent';

export type ListEmailProps = {
  folder: string;
  email: EmailMessage;
};

export function ListEmail(props: ListEmailProps) {
  const { email, folder } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const { user } = useAuth0();
  const [showHtml, setShowHtml] = useState<boolean>(!!email.html);

  const timestamp = getEmailTimestamp(email, folder);
  const userAddress = undefined;
  const from_ = email.from_ ?? (folder === 'drafts' ? userAddress : undefined);

  const scrollRef = createRef<HTMLDivElement>();

  return (
    <div>
      <button
        key={email.id}
        className="bg-gray-800 rounded-lg shadow-md py-2 px-4 mb-2 hover:bg-gray-600 transition-colors w-full text-left"
        onClick={() => setIsModalOpen(true)}
      >
        <h4 className="text-base font-semibold overflow-ellipsis overflow-hidden whitespace-nowrap">
          <span className="text-gray-400">
            {formatEmailTimestamp(email, folder)}
            {' | '}
          </span>
          {email.subject}
        </h4>
        {/* <p className="text-gray-400">{email.body}</p> */}
        <p className="text-gray-400">
          {emailAddressToStringShort(from_, userAddress)} {'->'}{' '}
          {email.to.map((a) => emailAddressToStringShort(a)).join(', ')}
        </p>
        {email.cc && email.cc.length > 0 && (
          <p className="text-gray-400">
            CC: {email.cc.map((a) => emailAddressToStringShort(a)).join(', ')}
          </p>
        )}
        {email.bcc && email.bcc.length > 0 && (
          <p className="text-gray-400">
            BCC: {email.bcc.map((a) => emailAddressToStringShort(a)).join(', ')}
          </p>
        )}
      </button>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div>
          <h2 className="text-lg font-bold mb-4 px-4 pt-4 text-gray-300">{email.subject}</h2>
        </div>
        <div
          ref={scrollRef}
          className="max-h-[80dvh] overflow-y-auto flex flex-col px-4 space-y-2 text-gray-300 pb-4 overflow-x-hidden"
        >
          <div>
            <p className="text-gray-400">From: {emailAddressToString(from_, userAddress)}</p>
            <p className="text-gray-400">
              To: {email.to.map((a) => emailAddressToString(a)).join(', ')}
            </p>
            {email.cc && email.cc.length > 0 && (
              <p className="text-gray-400">
                CC: {email.cc.map((a) => emailAddressToString(a)).join(', ')}
              </p>
            )}
            {email.bcc && email.bcc.length > 0 && (
              <p className="text-gray-400">
                BCC: {email.bcc.map((a) => emailAddressToString(a)).join(', ')}
              </p>
            )}
            <p className="text-gray-400">
              {formatEmailStatus(folder)}{' '}
              {timestamp
                ? 'at ' + format(timestamp, 'p') + ' on ' + format(timestamp, 'MMMM do, yyyy')
                : ''}
            </p>
          </div>
          {email.html && (
            <div>
              <Toggle
                checked={showHtml}
                disabled={!email.html}
                onChange={({ detail }) =>
                  setShowHtml(detail.checked && !!email.html ? true : false)
                }
              >
                HTML
              </Toggle>
            </div>
          )}
          {email.html && showHtml ? (
            <EmailContent htmlContent={email.html} />
          ) : (
            email.body.split('\n').map((line, index) =>
              line === '' ? (
                <br key={index} />
              ) : (
                <p key={index} className="text-white">
                  {line}
                </p>
              ),
            )
          )}
        </div>
      </Modal>
    </div>
  );
}

export type EmailListProps = {
  message: EmailListMessage;
  maxEmails?: number;
};

export function EmailList(props: EmailListProps) {
  const { message, maxEmails = 3 } = props;
  const { start, end, emails, folder } = message;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const startDate = parseISO(start);
  const endDate = parseISO(end);

  // Group emails by their respective timestamps
  const groupedEmails = useMemo(() => {
    return groupEmails(emails, folder);
  }, [emails, folder]);

  // Create a list of the first maxEmails emails
  const limitedEmails = useMemo(() => {
    const result: EmailMessage[] = [];
    let count = 0;
    const seenEmails: Set<string> = new Set();

    for (const date of Object.keys(groupedEmails)) {
      for (const email of groupedEmails[date]) {
        if (count >= maxEmails) {
          return result;
        }

        if (seenEmails.has(email.id!)) {
          continue;
        }
        seenEmails.add(email.id!);
        result.push(email);
        count++;
      }
    }

    return result;
  }, [groupedEmails, maxEmails]);

  const limitedGroupedEmails = useMemo(() => {
    const g = groupEmails(limitedEmails, folder);
    const ds = Object.keys(g);
    const result: Record<string, EmailMessage[]> = {};
    let count = 0;

    for (const date of ds) {
      for (const email of g[date]) {
        if (count >= maxEmails) {
          return result;
        }

        if (!result[date]) {
          result[date] = [];
        }
        result[date].push(email);
        count++;
      }
    }

    return result;
  }, [limitedEmails, folder, maxEmails]);

  const renderEmails = (gEmails: Record<string, EmailMessage[]>) => {
    const dates = Object.keys(gEmails);

    return (
      <>
        {dates.map((date) => {
          const emailsOnDate = gEmails[date];
          const formattedDate = format(parseISO(date), 'EEEE, MMM do');

          return (
            <div key={date} className="bg-gray-700 px-4 py-2 rounded-lg">
              <h3 className="text-base font-semibold mb-2">{formattedDate}</h3>
              {emailsOnDate.map((email) => (
                <ListEmail key={email.id} email={email} folder={folder} />
              ))}
            </div>
          );
        })}
      </>
    );
  };

  const scrollRef = createRef<HTMLDivElement>();

  return (
    <div className="text-gray-300">
      <h2 className="text-lg font-bold">
        Emails {formatEmailStatus(folder)} from {formatTitleRange(startDate, endDate)}
      </h2>
      <div className="space-y-2 py-2">
        {emails.length > 0 ? (
          <>
            {renderEmails(limitedGroupedEmails)}
            {emails.length > maxEmails && (
              <p
                className="text-blue-500 hover:text-blue-300 cursor-pointer mt-2 m-x-auto text-center"
                onClick={() => setIsModalOpen(true)}
              >
                Show all emails...
              </p>
            )}
          </>
        ) : (
          <p className="text-gray-400">No emails found within the specified time range.</p>
        )}
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div>
          <h2 className="text-lg font-bold mb-4 px-4 pt-4 text-gray-300">
            Emails {formatEmailStatus(folder)} from {format(parseISO(start), 'MMMM do, yyyy')} to{' '}
            {format(parseISO(end), 'MMMM do, yyyy')}
          </h2>
        </div>
        <div
          ref={scrollRef}
          className="max-h-[80dvh] overflow-y-auto flex flex-col px-4 space-y-2 text-gray-300 pb-4"
        >
          {renderEmails(groupedEmails)}
        </div>
      </Modal>
    </div>
  );
}
