import { CalendarEventUpdatedMessage } from '../../common/chat/types';
import { formatEventSpan } from '../../common/events';

export type CalendarEventUpdatedProps = {
  message: CalendarEventUpdatedMessage;
};

export function CalendarEventUpdated(props: CalendarEventUpdatedProps) {
  const { old, current } = props.message;

  const oldSpan = formatEventSpan(old);
  const currentSpan = formatEventSpan(current);

  return (
    <div className="space-y-2 text-gray-300">
      <h2 className="text-base font-bold text-blue-500">Event Updated</h2>
      <div className="bg-gray-700 rounded-lg shadow-md py-2 px-4 border border-blue-500">
        <h3 className="text-base font-semibold">
          {old.subject !== current.subject ? (
            <>
              <span className="line-through">{old.subject}</span>{' '}
              <span>
                {'-> '}
                {current.subject}
              </span>
            </>
          ) : (
            current.subject
          )}
        </h3>
        {oldSpan !== currentSpan && (
          <p className="text-gray-400">
            <span className="line-through">{oldSpan}</span>{' '}
            <span>
              {'-> '}
              {currentSpan}
            </span>
          </p>
        )}
        {oldSpan === currentSpan && <p className="text-gray-400">{currentSpan}</p>}
        {old.location !== current.location && (
          <p className="text-gray-400">
            <span className="line-through">{old.location}</span>{' '}
            <span>
              {'-> '}
              {current.location}
            </span>
          </p>
        )}
        {old.location === current.location && <p className="text-gray-400">{current.location}</p>}
        {/* {current.body && <p className="event-body text-gray-300 mt-1">{current.body}</p>} */}
        {/* TODO better way to display body and categories in click modal */}
        {/* {current.categories && current.categories.length > 0 && (
          <div className="event-categories mt-2 flex flex-wrap">
            {current.categories.map((category, index) => (
              <span
                key={`${props.message.id}-category-${index}`}
                className="mb-2 px-2 py-1 bg-blue-700 text-blue-200 rounded-full text-xs"
              >
                {category}
              </span>
            ))}
          </div>
        )} */}
      </div>
    </div>
  );
}
