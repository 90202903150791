import { Box, Spinner, StatusIndicator } from '@cloudscape-design/components';

import { LoadingMessage } from '../../common/chat/types';
import { toolCallMessages, ToolCallName } from '../../common/chat/tools';

export type RenderedLoadingMessageProps = {
  message: LoadingMessage;
};

export default function RenderedTextMessage(props: RenderedLoadingMessageProps) {
  const loadingMessage = props.message;
  const text = loadingMessage.error
    ? toolCallMessages[loadingMessage.name as ToolCallName] + ' error' || 'Error'
    : loadingMessage.complete
    ? toolCallMessages[loadingMessage.name as ToolCallName] + ' complete' || 'Complete'
    : toolCallMessages[loadingMessage.name as ToolCallName] || 'Loading';

  return (
    <Box variant="p">
      {loadingMessage.error ? (
        <StatusIndicator type="error">{loadingMessage.name ? text : ''}</StatusIndicator>
      ) : loadingMessage.complete ? (
        <StatusIndicator type="success">{loadingMessage.name ? text : ''}</StatusIndicator>
      ) : (
        <>
          <Spinner size="normal" /> {loadingMessage.name ? text : ''}
        </>
      )}
    </Box>
  );
}
