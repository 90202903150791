import AudioMessagePlayer from '../../components/chat/AudioMessagePlayer';
import { BrowseItems } from '../../components/chat/BrowseItems';
import { CalendarEventCreated } from '../../components/chat/CalendarEventCreated';
import { CalendarEventDeleted } from '../../components/chat/CalendarEventDeleted';
import { CalendarEventList } from '../../components/chat/CalendarEventList';
import { CalendarEventUpdated } from '../../components/chat/CalendarEventUpdated';
import { DeleteEmail } from '../../components/chat/DeleteEmail';
import { DirectionsMap } from '../../components/chat/DirectionsMap';
import { EmailList } from '../../components/chat/EmailList';
import { MemoryCreated } from '../../components/chat/MemoryCreated';
import { MemoryDeleted } from '../../components/chat/MemoryDeleted';
import { MemoryUpdated } from '../../components/chat/MemoryUpdated';
import { PeopleSearch } from '../../components/chat/PeopleSearch';
import { PlaceDetailsInfo } from '../../components/chat/PlaceDetailsInfo';
import RenderedLoadingMessage from '../../components/chat/RenderedLoadingMessage';
import RenderedTextMessage from '../../components/chat/RenderedTextMessage';
import { SearchItems } from '../../components/chat/SearchItems';
import { SearchPlacesList } from '../../components/chat/SearchPlacesList';
import { SendEmail } from '../../components/chat/SendEmail';
import { UpdateEmail } from '../../components/chat/UpdateEmail';
import { WriteEmail } from '../../components/chat/WriteEmail';

import {
  BrowseToolCallDone,
  CalendarEventCreatedToolCallDone,
  CalendarEventDeletedToolCallDone,
  CalendarEventUpdatedToolCallDone,
  ChatMessage,
  CreateMemoryToolCallDone,
  DeleteEmailToolCallDone,
  DeleteMemoryToolCallDone,
  EmailListToolCallDone,
  ListEventsToolCallDone,
  PeopleListToolCallDone,
  SearchToolCallDone,
  SendEmailToolCallDone,
  ToolCallDone,
  UpdateEmailToolCallDone,
  UpdateMemoryToolCallDone,
  WriteEmailToolCallDone,
  DirectionsToolCallDone,
  SearchPlacesToolCallDone,
  PlaceDetailsToolCallDone,
} from './types';

export enum MessageType {
  Text = 'text',
  Audio = 'audio',
  Loading = 'loading',
  CalendarEventCreated = 'calendar_event_created',
  CalendarEventUpdated = 'calendar_event_updated',
  CalendarEventDeleted = 'calendar_event_deleted',
  CalendarEventList = 'calendar_event_list',
  EmailList = 'email_list',
  WriteEmail = 'write_email',
  UpdateEmail = 'update_email',
  DeleteEmail = 'delete_email',
  SendEmail = 'send_email',
  PeopleList = 'search_people',
  MemoryCreated = 'memory_created',
  MemoryUpdated = 'memory_updated',
  MemoryDeleted = 'memory_deleted',
  Search = 'search',
  Browse = 'browse',
  Directions = 'directions',
  SearchPlaces = 'search_places',
  PlaceDetails = 'place_details',
}

export enum ToolCallName {
  CreateEvent = 'create_event',
  UpdateEvent = 'update_event',
  DeleteEvent = 'delete_event',
  ListEvents = 'list_events',
  ListEmails = 'list_emails',
  WriteEmail = 'write_email',
  UpdateEmail = 'update_email',
  DeleteEmail = 'delete_email',
  SendEmail = 'send_email',
  SearchPeople = 'search_people',
  MemoryCreated = 'create_memory',
  MemoryUpdated = 'update_memory',
  MemoryDeleted = 'delete_memory',
  Search = 'search',
  Browse = 'browse',
  Directions = 'directions',
  SearchPlaces = 'search_places',
  PlaceDetails = 'place_details',
}

export const messageComponents: Record<MessageType, React.FC<any>> = {
  [MessageType.Loading]: RenderedLoadingMessage,
  [MessageType.Text]: RenderedTextMessage,
  [MessageType.Audio]: AudioMessagePlayer,
  [MessageType.CalendarEventCreated]: CalendarEventCreated,
  [MessageType.CalendarEventUpdated]: CalendarEventUpdated,
  [MessageType.CalendarEventDeleted]: CalendarEventDeleted,
  [MessageType.CalendarEventList]: CalendarEventList,
  [MessageType.EmailList]: EmailList,
  [MessageType.WriteEmail]: WriteEmail,
  [MessageType.UpdateEmail]: UpdateEmail,
  [MessageType.DeleteEmail]: DeleteEmail,
  [MessageType.SendEmail]: SendEmail,
  [MessageType.PeopleList]: PeopleSearch,
  [MessageType.MemoryCreated]: MemoryCreated,
  [MessageType.MemoryUpdated]: MemoryUpdated,
  [MessageType.MemoryDeleted]: MemoryDeleted,
  [MessageType.Search]: SearchItems,
  [MessageType.Browse]: BrowseItems,
  [MessageType.Directions]: DirectionsMap,
  [MessageType.SearchPlaces]: SearchPlacesList,
  [MessageType.PlaceDetails]: PlaceDetailsInfo,
  // Add other message types here...
};

export const toolCallMessages: Record<ToolCallName, string> = {
  [ToolCallName.CreateEvent]: 'Creating event',
  [ToolCallName.UpdateEvent]: 'Updating event',
  [ToolCallName.DeleteEvent]: 'Deleting event',
  [ToolCallName.ListEvents]: 'Loading events',
  [ToolCallName.ListEmails]: 'Loading emails',
  [ToolCallName.WriteEmail]: 'Drafting email',
  [ToolCallName.UpdateEmail]: 'Updating email',
  [ToolCallName.DeleteEmail]: 'Deleting email',
  [ToolCallName.SendEmail]: 'Sending email',
  [ToolCallName.SearchPeople]: 'Searching people',
  [ToolCallName.MemoryCreated]: 'Creating memory',
  [ToolCallName.MemoryUpdated]: 'Updating memory',
  [ToolCallName.MemoryDeleted]: 'Deleting memory',
  [ToolCallName.Search]: 'Searching',
  [ToolCallName.Browse]: 'Browsing',
  [ToolCallName.Directions]: 'Getting directions',
  [ToolCallName.SearchPlaces]: 'Searching places',
  [ToolCallName.PlaceDetails]: 'Loading place details',
  // Add other tool calls here...
};

export const toolCallHandlers: Record<ToolCallName, (obj: ToolCallDone) => ChatMessage> = {
  [ToolCallName.CreateEvent]: (obj) => {
    const specificObj = obj as CalendarEventCreatedToolCallDone;

    return {
      type: MessageType.CalendarEventCreated,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      event: specificObj.value.event,
    };
  },
  [ToolCallName.UpdateEvent]: (obj) => {
    const specificObj = obj as CalendarEventUpdatedToolCallDone;

    return {
      type: MessageType.CalendarEventUpdated,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      old: specificObj.value.old,
      current: specificObj.value.current,
    };
  },
  [ToolCallName.DeleteEvent]: (obj) => {
    const specificObj = obj as CalendarEventDeletedToolCallDone;

    return {
      type: MessageType.CalendarEventDeleted,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      event: specificObj.value.event,
    };
  },
  [ToolCallName.ListEvents]: (obj) => {
    const specificObj = obj as ListEventsToolCallDone;

    return {
      type: MessageType.CalendarEventList,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      start: specificObj.value.start,
      end: specificObj.value.end,
      display: specificObj.value.display,
      events: specificObj.value.events,
    };
  },
  [ToolCallName.ListEmails]: (obj) => {
    const specificObj = obj as EmailListToolCallDone;

    return {
      type: MessageType.EmailList,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      folder: specificObj.value.folder,
      start: specificObj.value.start,
      end: specificObj.value.end,
      display: specificObj.value.display,
      emails: specificObj.value.emails,
    };
  },
  [ToolCallName.WriteEmail]: (obj) => {
    const specificObj = obj as WriteEmailToolCallDone;

    return {
      type: MessageType.WriteEmail,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      email: specificObj.value.email,
    };
  },
  [ToolCallName.UpdateEmail]: (obj) => {
    const specificObj = obj as UpdateEmailToolCallDone;

    return {
      type: MessageType.UpdateEmail,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      old: specificObj.value.old,
      current: specificObj.value.current,
    };
  },
  [ToolCallName.DeleteEmail]: (obj) => {
    const specificObj = obj as DeleteEmailToolCallDone;

    return {
      type: MessageType.DeleteEmail,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      email: specificObj.value.email,
    };
  },
  [ToolCallName.SendEmail]: (obj) => {
    const specificObj = obj as SendEmailToolCallDone;

    return {
      type: MessageType.SendEmail,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      email: specificObj.value.email,
    };
  },
  [ToolCallName.SearchPeople]: (obj) => {
    const specificObj = obj as PeopleListToolCallDone;

    return {
      type: MessageType.PeopleList,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      people: specificObj.value.people,
      display: specificObj.value.display,
    };
  },
  [ToolCallName.MemoryCreated]: (obj) => {
    const specificObj = obj as CreateMemoryToolCallDone;

    return {
      type: MessageType.MemoryCreated,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      memory: specificObj.value.memory,
    };
  },
  [ToolCallName.MemoryUpdated]: (obj) => {
    const specificObj = obj as UpdateMemoryToolCallDone;

    return {
      type: MessageType.MemoryUpdated,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      current: specificObj.value.current,
      old: specificObj.value.old,
    };
  },
  [ToolCallName.MemoryDeleted]: (obj) => {
    const specificObj = obj as DeleteMemoryToolCallDone;

    return {
      type: MessageType.MemoryDeleted,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      memory: specificObj.value.memory,
    };
  },
  [ToolCallName.Search]: (obj) => {
    const specificObj = obj as SearchToolCallDone;

    return {
      type: MessageType.Search,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      query: specificObj.value.query,
      items: specificObj.value.items,
    };
  },
  [ToolCallName.Browse]: (obj) => {
    const specificObj = obj as BrowseToolCallDone;

    return {
      type: MessageType.Browse,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      items: specificObj.value.items,
    };
  },
  [ToolCallName.Directions]: (obj) => {
    const specificObj = obj as DirectionsToolCallDone;

    return {
      type: MessageType.Directions,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      origin: specificObj.value.origin,
      destination: specificObj.value.destination,
      mode: specificObj.value.mode,
      directions: specificObj.value.directions,
    };
  },
  [ToolCallName.SearchPlaces]: (obj) => {
    const specificObj = obj as SearchPlacesToolCallDone;

    return {
      type: MessageType.SearchPlaces,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      query: specificObj.value.query,
      location: specificObj.value.location,
      radius: specificObj.value.radius,
      places: specificObj.value.places,
    };
  },
  [ToolCallName.PlaceDetails]: (obj) => {
    const specificObj = obj as PlaceDetailsToolCallDone;

    return {
      type: MessageType.PlaceDetails,
      created_at: new Date().toISOString().split('.')[0],
      role: 'assistant',
      details: specificObj.value.details,
    };
  },
};
