import { createRef, useState } from 'react';
import { Link } from '@cloudscape-design/components';

import { SearchPlacesMessage, Place } from '../../common/chat/types';
import { Modal } from '../Modal';

export type SearchPlacesListProps = {
  message: SearchPlacesMessage;
};

export function SearchPlacesList(props: SearchPlacesListProps) {
  const { query, location, radius, places } = props.message;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const maxItems = 2;

  const renderPlaces = (places: Place[], allPlaces: Place[]) => {
    return (
      <div className="space-y-2 text-gray-300 px-2 py-2">
        <h2 className="text-base font-bold text-gray-300">Found {allPlaces.length} places</h2>
        <div className="bg-gray-800 rounded-lg border-gray-500 border-2">
          {places.map((place, idx) => (
            <div
              key={place.place_id}
              className={`bg-gray-800 py-2 px-4 border-gray-500 flex items-center ${
                idx === 0 ? 'rounded-t-lg' : ''
              } ${idx === places.length - 1 ? 'rounded-b-lg' : 'border-b-2'}`}
            >
              {place.icon && (
                <img alt={`${place.name} icon`} className="w-10 h-10 mr-4" src={place.icon} />
              )}
              <div>
                <h4 className="text-base font-bold">{place.name}</h4>
                {place.address && <p className="text-gray-400 text-sm">{place.address}</p>}
                <p className="text-gray-400">
                  Rating: {place.rating ?? 'N/A'}{' '}
                  {place.user_ratings_total && `(${place.user_ratings_total} reviews)`}
                  {place.price_level && (
                    <span className="ml-2">{'$'.repeat(place.price_level)}</span>
                  )}
                </p>
                {place.open_now !== undefined && (
                  <span
                    className={`text-gray-400 ${
                      place.open_now ? 'text-green-500' : 'text-red-500'
                    }`}
                  >
                    {place.open_now ? 'Open now' : 'Closed'}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const scrollRef = createRef<HTMLDivElement>();

  return (
    <div className="space-y-2 text-gray-300">
      {renderPlaces(places.slice(0, maxItems), places)}
      {places.length > maxItems && (
        <p
          className="text-blue-500 hover:text-blue-300 cursor-pointer mt-2 m-x-auto text-center"
          onClick={() => setIsModalOpen(true)}
        >
          Show all...
        </p>
      )}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div
          ref={scrollRef}
          className="max-h-[80dvh] overflow-y-auto flex flex-col px-4 space-y-2 text-gray-300 pb-4"
        >
          {renderPlaces(places, places)}
        </div>
      </Modal>
    </div>
  );
}
