import { Header, Box, HelpPanel, Icon, Link } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

export function QuestionsHelp() {
  const navigate = useNavigate();

  return (
    <HelpPanel
      footer={
        <div>
          <Header variant="h3">
            Learn more <Icon name="external" />
          </Header>
          <ul>
            <li>
              <Link
                href="https://ojs.aaai.org/index.php/ICWSM/article/view/19360"
                target="_blank"
                external
              >
                A journey of stance, ontological commitments and moral foundations
              </Link>
            </li>
            <li>
              <Link
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9148945/"
                target="_blank"
                external
              >
                Identifying the framing of beliefs towards vaccine confidence in Twitter discourse
              </Link>
            </li>
          </ul>
        </div>
      }
      header={<Header variant="h2">About Questions</Header>}
    >
      <div>
        <Header variant="h3">Why questions?</Header>
        <Box variant="p">
          Questions, such as those derived from the{' '}
          <Link href="https://pubmed.ncbi.nlm.nih.gov/30735925/" target="_blank" external>
            Vaccine Confidence Repository
          </Link>
          , have been shown to be effective in eliciting vaccine hesitancy opinions and attitudes.
          Vaccine hesitancy is often measured using questionnaires. For example the{' '}
          <Link
            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9148945/"
            target="_blank"
            external
          >
            Vaccine Confidence Scale
          </Link>{' '}
          surveyed 9,623 parents over the phone in the United States and utilized their responses to
          questions (on a scale from 0-10) to determine vaccine hesitancy.
        </Box>
        <Box variant="p">
          Unlike questionnaires, which are often lengthy, require a significant amount of time to
          complete, and require a trained interviewer, questions can be asked quickly and easily on
          social media platforms such as Twitter. Users already express their opinions and attitudes
          publicly on Twitter, therefore opinion-based questions can be used to discover these
          opinions and attitudes automatically.
        </Box>
        <Header variant="h3">What is an opinion-based question?</Header>
        <Box variant="p">
          Opinion-based questions are questions that elicit attitudes. For example, the question "Do
          you think vaccines are safe?" elicits an attitude about vaccines. Similarly, the question
          "Do you think vaccines are dangerous?" can elicit a different opinion or attitude about
          vaccines. As opposed to opinion-based questions, factual questions elicit facts. For
          example, the question "How many vaccines are there?" elicits a fact about vaccines.
          Opinion-based questions are more effective in eliciting attitudes than factual questions,
          as they are more likely to be answered with an attitude, and there are no right or wrong
          answers.
        </Box>
        <Header variant="h3">How were questions selected?</Header>
        <Box variant="p">
          Questions were derived from the{' '}
          <Link href="https://pubmed.ncbi.nlm.nih.gov/30735925/" target="_blank" external>
            Vaccine Confidence Repository
          </Link>
          , with a focus on questions that elicit opinions about COVID-19 vaccines. Questions were
          selected based on their relevance to the COVID-19 pandemic, their ability to elicit
          opinions, and their ability to be answered on Twitter. Questions were also selected based
          on their ability to elicit opinions about certain vaccine themes, such as vaccine
          confidence, vaccine safety, vaccine effectiveness, vaccine complacency, freedom to
          vaccinate, and conspiracies surrounding vaccines.
        </Box>
        <Header variant="h3">How were framings identified?</Header>
        <Box variant="p">
          Framings were inferred from the Twitter opinion-based answers by computational linguistics
          experts from the{' '}
          <Link href="https://www.hlt.utdallas.edu/" target="_blank" external>
            Human Language Technology Research Institute (HLTRI)
          </Link>
          , informed by the{' '}
          <Link href="https://aclanthology.org/N04-1019/" target="_blank" external>
            pyramid method
          </Link>
          .
        </Box>
      </div>
    </HelpPanel>
  );
}
