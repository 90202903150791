import {
  AppLayout,
  ContentLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
  Grid,
} from '@cloudscape-design/components';

import { LocalNavigation, Breadcrumbs, LocalNavigationProps } from '../navigation';
import { PublicationsList } from '../common/publications';
import { NAV_WIDTH } from '../common/window';

import Publications from './publications';

export default function PublicationsPage(props: LocalNavigationProps) {
  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Publications', href: '' }]} />}
      content={
        <ContentLayout
          header={
            <Container
              header={
                <Header description="My published research" variant="h1">
                  Publications
                </Header>
              }
              disableContentPaddings
            />
          }
        >
          <Grid
            gridDefinition={[
              { colspan: { default: 12, s: 7 } },
              { colspan: { default: 12, s: 3 } },
            ]}
          >
            <PublicationsList publications={Publications} displayImages />
            {/* <PublicationCards publications={Publications} /> */}
            <Bibliographies />
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      navigation={<LocalNavigation />}
      navigationOpen={props.navigationOpen}
      navigationWidth={NAV_WIDTH}
      toolsHide
      onNavigationChange={(event) => props.setNavigationOpen(event.detail.open)}
    />
  );
}

export function Bibliographies() {
  return (
    <Container header={<Header variant="h2">Bibliographies</Header>}>
      <SpaceBetween direction="vertical" size="s">
        <Link
          href="https://scholar.google.com/citations?user=G5BQKugAAAAJ"
          target="_blank"
          variant="secondary"
          external
        >
          Google Scholar
        </Link>
        <Link
          href="https://www.semanticscholar.org/author/Maxwell-Alan-Weinzierl/122449521"
          target="_blank"
          variant="secondary"
          external
        >
          Semantic Scholar
        </Link>
        <Link
          href="https://dblp.org/pid/277/7481.html"
          target="_blank"
          variant="secondary"
          external
        >
          DBLP
        </Link>
        <Link
          href="https://orcid.org/0000-0002-8049-7453"
          target="_blank"
          variant="secondary"
          external
        >
          <img
            alt="ORCID logo"
            height="16"
            src="https://info.orcid.org/wp-content/uploads/2019/11/orcid_16x16.png"
            width="16"
          />
          ORCID
        </Link>
      </SpaceBetween>
    </Container>
  );
}
