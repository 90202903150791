import { parseISO, isSameDay, format, isSameYear, max, min, eachDayOfInterval } from 'date-fns';

import { CalendarEvent } from './chat/types';

export function formatCrossDayDate(date: Date): string {
  if (date.getHours() === 0 && date.getMinutes() === 0) {
    return format(date, 'MMM do');
  }

  if (date.getHours() === 23 && date.getMinutes() === 59) {
    return format(date, 'MMM do');
  }

  return format(date, 'Pp');
}

export function formatAllDayCrossDayEvent(start: Date, end: Date): string {
  if (isSameYear(start, end)) {
    return `${format(start, 'MMM do')} - ${format(end, 'MMM do')}`;
  }

  return `${format(start, 'PPP')} - ${format(end, 'PPP')}`;
}

export function formatEventSpan(event: CalendarEvent) {
  const eventStartDate = parseISO(event.start);
  const eventEndDate = parseISO(event.end);
  const sameDay = isSameDay(eventStartDate, eventEndDate);

  return sameDay
    ? `${format(eventStartDate, 'MMM do')}, ${format(eventStartDate, 'p')} - ${format(
        eventEndDate,
        'p',
      )}`
    : event.is_all_day
    ? formatAllDayCrossDayEvent(eventStartDate, eventEndDate)
    : `${formatCrossDayDate(eventStartDate)} - ${formatCrossDayDate(eventEndDate)}`;
}

export function formatDayEventSpan(event: CalendarEvent): string {
  const eventStartDate = parseISO(event.start);
  let eventEndDate = parseISO(event.end);

  eventEndDate =
    eventEndDate.getHours() === 0 && eventEndDate.getMinutes() === 0
      ? new Date(eventEndDate.getTime() - 1000)
      : eventEndDate;

  const isAllDay =
    event.is_all_day ||
    (eventStartDate.getHours() === 0 &&
      eventStartDate.getMinutes() === 0 &&
      eventEndDate.getHours() === 23 &&
      eventEndDate.getMinutes() === 59);
  const sameDay = isSameDay(eventStartDate, eventEndDate);

  if (isAllDay) {
    if (sameDay) {
      return `All day`;
    }

    return `${format(eventStartDate, 'MMM do')} - ${format(eventEndDate, 'MMM do')}`;
  }

  if (sameDay) {
    return `${format(eventStartDate, 'p')} - ${format(eventEndDate, 'p')}`;
  }

  return `${format(eventStartDate, 'MMM do, p')} - ${format(eventEndDate, 'MMM do, p')}`;
}

// Helper function to get all dates in the range
export function getAllDatesInRange(
  eventStart: string,
  eventEnd: string,
  listStart: string,
  listEnd: string,
) {
  const eventStartDate = parseISO(eventStart);
  let eventEndDate = parseISO(eventEnd);
  const listStartDate = parseISO(listStart);
  let listEndDate = parseISO(listEnd);

  // if end is 00:00:00 then subtract a second to make it inclusive
  listEndDate =
    listEndDate.getHours() === 0 && listEndDate.getMinutes() === 0
      ? new Date(listEndDate.getTime() - 1000)
      : listEndDate;
  eventEndDate =
    eventEndDate.getHours() === 0 && eventEndDate.getMinutes() === 0
      ? new Date(eventEndDate.getTime() - 1000)
      : eventEndDate;

  const validStart = max([eventStartDate, listStartDate]);
  const validEnd = min([eventEndDate, listEndDate]);

  return eachDayOfInterval({ start: validStart, end: validEnd }).map((date) =>
    format(date, 'yyyy-MM-dd'),
  );
}

export function formatTitleRange(start: Date, end: Date): string {
  // if end is 00:00:00 then subtract a second to make it inclusive
  const endAdjusted =
    end.getHours() === 0 && end.getMinutes() === 0 ? new Date(end.getTime() - 1000) : end;

  if (isSameYear(start, end)) {
    if (isSameDay(start, endAdjusted)) {
      return format(endAdjusted, 'EEEE, MMM do, yyyy');
    }

    return `${format(start, 'EEEE, MMM do')} to ${format(endAdjusted, 'EEEE, MMM do')}, ${format(
      start,
      'yyyy',
    )}`;
  }

  return `${format(start, 'EEEE, PPP')} to ${format(endAdjusted, 'EEEE, PPP')}`;
}

export function groupEvents(events: CalendarEvent[], start: string, end: string) {
  return events.reduce((groups, event) => {
    const eventDates = getAllDatesInRange(event.start, event.end, start, end);

    for (const date of eventDates) {
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(event);
    }

    return groups;
  }, {} as Record<string, CalendarEvent[]>);
}
