import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './App';

const queryClient = new QueryClient({
  // queryCache: new QueryCache({
  //   onError: (error, query) => {
  //     const { title, description } = getErrorDetails(error);
  //     // Report the error to Sentry
  //     Sentry.captureException(error, {
  //       tags: {
  //         queryKey: `${query.queryKey}`,
  //         title,
  //         description,
  //       },
  //     });
  //   },
  // }),
  // mutationCache: new MutationCache({
  //   onError: (error) => {
  //     const { title, description } = getErrorDetails(error);
  //     // Report the error to Sentry
  //     Sentry.captureException(error, {
  //       tags: {
  //         title,
  //         description,
  //       },
  //     });
  //   },
  // }),
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <App />
      </QueryParamProvider>
    </QueryClientProvider>
  </BrowserRouter>,
);
