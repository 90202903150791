import { useCallback, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { createQuestion, createQuestionAudio } from '../api/questions';
import {
  AudioMessageRequest,
  ChatMessage,
  LoadingMessage,
  MessageRequest,
  TextMessage,
} from '../chat/types';

export function useQuestionApi(
  setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>,
  location?: string,
) {
  const create = useMutation({
    mutationFn: async (message: MessageRequest) => {
      console.log('streaming question:', message);

      return createQuestion(message, setMessages);
    },
    // TODO: check if this is ok
    retry: 3,
    onError: (error) => {
      console.error(error);
      // occurs after all retries have failed
      // remove the last message (loading message)
      setMessages((messages) => [...messages.slice(0, -1)]);
    },
  });
  const createAudio = useMutation({
    mutationFn: async (message: AudioMessageRequest) => {
      console.log('streaming question audio:', message);

      return createQuestionAudio(message, setMessages);
    },
    // TODO: check if this is ok
    retry: 3,
    onError: (error) => {
      console.error(error);
      // occurs after all retries have failed
      // remove the last message (loading message)
      setMessages((messages) => [...messages.slice(0, -1)]);
    },
  });

  const loading = create.isPending || createAudio.isPending;

  const error = create.error || createAudio.error;

  const askAudio = useCallback(
    async (audio: Blob, mime_type: string, sampleRate: number) => {
      if (!audio) {
        return;
      }

      if (loading) {
        return;
      }

      const newLoadingMessage: LoadingMessage = {
        type: 'loading',
        created_at: new Date().toISOString().split('.')[0],
        role: 'assistant',
      };

      setMessages((messages) => [newLoadingMessage]);
      createAudio.reset();

      console.log('sending audio');
      await createAudio.mutateAsync({
        audio,
        mime_type,
        sample_rate: sampleRate,
        location: location ?? 'default',
      });
    },
    [createAudio],
  );

  const ask = useCallback(
    async (message: string) => {
      const text = message.trim();

      if (!text) {
        return;
      }

      if (loading) {
        return;
      }

      console.log('adding message');
      const newMessage: TextMessage = {
        type: 'text',
        created_at: new Date().toISOString().split('.')[0],
        role: 'user',
        message: text,
      };
      const newLoadingMessage: LoadingMessage = {
        type: 'loading',
        created_at: new Date().toISOString().split('.')[0],
        role: 'assistant',
      };

      // preemptively adding user's message to messages to look more responsive
      setMessages((messages) => [newMessage, newLoadingMessage]);

      // reset error states
      create.reset();

      console.log('sending to chat');
      await create.mutateAsync({ message: text, location: location ?? 'default' });
    },
    [create],
  );
  const reset = useCallback(() => {
    setMessages([]);
    // reset error states
    create.reset();
  }, [setMessages, create]);

  return {
    ask,
    loading,
    error,
    askAudio,
  };
}
