import { Buffer } from 'buffer';

import { useMemo } from 'react';

type EmailContentProps = {
  htmlContent: string;
};

// const decode = (base64: string) => {
//   return Buffer.from(base64, 'base64').toString('utf-8');
// };

export function EmailContent(props: EmailContentProps) {
  // const sanitizedContent = useMemo(() => {
  //   const base64Html = props.htmlContent;
  //   const decodedHtml = decode(base64Html);

  //   // return DOMPurify.sanitize(decodedHtml);
  //   return decodedHtml;
  // }, [props.htmlContent]);

  return (
    <div
      // dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      className="responsive-table prose sm:prose-sm max-w-none bg-white px-4 py-2 rounded-lg"
    />
  );
}
