import { useState } from 'react';
import { Link } from '@cloudscape-design/components';

import { BrowseItem, BrowseMessage } from '../../common/chat/types';
import { Modal } from '../Modal';

export type BrowseItemsProps = {
  message: BrowseMessage;
};

export function BrowseItems(props: BrowseItemsProps) {
  const { items } = props.message;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const maxItems = 3;

  const renderItems = (items: BrowseItem[], allItems: BrowseItem[]) => {
    return (
      <div className="space-y-2 text-gray-300 px-2 py-2">
        <h2 className="text-base font-bold text-gray-300">Browsed {allItems.length} sites</h2>
        <div className="bg-gray-800 rounded-lg border-gray-500 border-2">
          {items.map((item, idx) => (
            <Link href={item.url} target="_blank">
              <div
                key={item.url}
                className={`bg-gray-800 py-2 px-4 border-gray-500 ${
                  items.length === 1
                    ? 'rounded-lg'
                    : idx === items.length - 1
                    ? 'rounded-b-lg'
                    : idx === 0
                    ? 'rounded-t-lg border-b-2'
                    : 'border-b-2'
                }`}
              >
                <p className="text-gray-400">{item.url}</p>
                {/* <p className="text-gray-400">{item.content}</p> */}
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-2 text-gray-300">
      {renderItems(items.slice(0, maxItems), items)}
      {items.length > maxItems && (
        <p
          className="text-blue-500 hover:text-blue-300 cursor-pointer mt-2 m-x-auto text-center"
          onClick={() => setIsModalOpen(true)}
        >
          Show all...
        </p>
      )}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {renderItems(items, items)}
      </Modal>
    </div>
  );
}
