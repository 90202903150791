import { useState } from 'react';
import {
  Box,
  Button,
  Container,
  SpaceBetween,
  AreaChart,
  BarChart,
} from '@cloudscape-design/components';
import Select, { SelectProps } from '@cloudscape-design/components/select';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import DateRangePicker, {
  DateRangePickerProps,
} from '@cloudscape-design/components/date-range-picker';

import { StanceMeasurement } from '../../common/data/types';

function useGroupParams(defaultValue: SelectProps.Option, disableUrlQueryParams?: boolean) {
  const [grouping, setGrouping] = useQueryParams({
    label: StringParam,
    value: StringParam,
  });

  const [localGrouping, localSetGrouping] = useState<SelectProps.Option>(defaultValue);

  if (disableUrlQueryParams) {
    return {
      grouping: localGrouping,
      setGrouping: localSetGrouping,
    };
  }

  return { grouping, setGrouping };
}

function useRangeParams(disableUrlQueryParams?: boolean) {
  const [range, setRangeUrl] = useQueryParams({
    startDate: StringParam,
    endDate: StringParam,
    type: StringParam,
    key: StringParam,
    amount: NumberParam,
    unit: StringParam,
  });
  const setRange = (value: DateRangePickerProps.Value | null) => {
    setRangeUrl(
      value ?? {
        startDate: null,
        endDate: null,
        type: '',
        key: null,
        amount: null,
        unit: null,
      },
    );
  };

  const [localRange, localSetRange] = useState<DateRangePickerProps.Value | null>(null);

  if (disableUrlQueryParams) {
    return {
      range: localRange,
      setRange: localSetRange,
    };
  }

  return { range, setRange };
}

function getGroupings() {
  const options: SelectProps.Option[] = [
    { label: 'Daily', value: 'day' },
    { label: 'Weekly', value: 'week' },
    { label: 'Monthly', value: 'month' },
    { label: 'Yearly', value: 'year' },
  ];

  return options;
}

function getGraphType(grouping?: string | null) {
  switch (grouping) {
    case 'day':
      return 'area';
    case 'week':
      return 'area';
    case 'month':
      return 'bar';
    case 'year':
      return 'bar';
    default:
      return 'area';
  }
}

export type StanceGraphProps = {
  acceptSeries: StanceMeasurement[];
  rejectSeries: StanceMeasurement[];
  header?: React.ReactNode;
  disableUrlQueryParams?: boolean;
};

function formatGroupedDate(date: Date, grouping?: string | null) {
  switch (grouping) {
    case 'year':
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
      });
    case 'month':
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
      });
    case 'week':
    default:
    case 'day':
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
  }
}

function isValidRange(
  range: DateRangePickerProps.Value | null,
): DateRangePickerProps.ValidationResult {
  if (range === null) {
    return { valid: true };
  }

  if (range.type === 'absolute') {
    const [startDateWithoutTime] = range.startDate.split('T');
    const [endDateWithoutTime] = range.endDate.split('T');

    if (!startDateWithoutTime || !endDateWithoutTime) {
      return {
        valid: false,
        errorMessage:
          'The selected date range is incomplete. Select a start and end date for the date range.',
      };
    }

    if (new Date(range.startDate).valueOf() - new Date(range.endDate).valueOf() > 0) {
      return {
        valid: false,
        errorMessage:
          'The selected date range is invalid. The start date must be before the end date.',
      };
    }
  }

  return { valid: true };
}

function getRelativeOptions(): ReadonlyArray<DateRangePickerProps.RelativeOption> {
  return [
    {
      key: 'previous-6-months',
      amount: 6,
      unit: 'month',
      type: 'relative',
    },
    {
      key: 'previous-1-years',
      amount: 1,
      unit: 'year',
      type: 'relative',
    },
    {
      key: 'previous-5-years',
      amount: 5,
      unit: 'year',
      type: 'relative',
    },
  ];
}

function getMonday(date: Date) {
  const d = new Date(date);
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday

  return new Date(d.setDate(diff));
}

function getGroupingKey(date: Date, grouping?: string) {
  switch (grouping) {
    case 'day':
      return date;
    case 'week':
      return getMonday(date);
    case 'month':
      return new Date(date.getFullYear(), date.getMonth(), 1);
    case 'year':
      return new Date(date.getFullYear(), 0, 1);
    default:
      return date;
  }
}

function groupSeries(series: StanceMeasurement[], grouping?: string) {
  if (!grouping || grouping === 'day') {
    return series;
  }

  const grouped: { [key: string]: StanceMeasurement } = {};

  for (const measurement of series) {
    const date = getGroupingKey(measurement.x, grouping);
    const key = date.toISOString();

    if (grouped[key] !== undefined) {
      grouped[key].y += measurement.y;
    } else {
      grouped[key] = {
        x: date,
        y: measurement.y,
      };
    }
  }

  return Object.values(grouped);
}

function filterSeries(
  series: StanceMeasurement[],
  range?: DateRangePickerProps.AbsoluteValue | DateRangePickerProps.RelativeValue | null,
) {
  if (!range) {
    return series;
  }

  if (range.type === 'absolute') {
    return series.filter((m) => {
      return m.x >= new Date(range.startDate) && m.x <= new Date(range.endDate);
    });
  }

  if (range.type === 'relative') {
    const now = new Date();
    let startDate: Date;

    switch (range.unit) {
      case 'day':
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - range.amount);
        break;
      case 'week':
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - range.amount * 7);
        break;
      case 'month':
        startDate = new Date(now.getFullYear(), now.getMonth() - range.amount, now.getDate());
        break;
      case 'year':
        startDate = new Date(now.getFullYear() - range.amount, now.getMonth(), now.getDate());
        break;
      default:
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    }

    return series.filter((m) => {
      return m.x >= startDate && m.x <= now;
    });
  }

  return series;
}

export function StanceTimeGraph(props: StanceGraphProps) {
  const defaultGrouping = { label: 'Monthly', value: 'month' };
  const { grouping, setGrouping } = useGroupParams(defaultGrouping, props.disableUrlQueryParams);
  const groupingValue = (grouping?.label ? grouping : defaultGrouping) as SelectProps.Option;
  const groupingOptions = getGroupings();

  const graphType = getGraphType(groupingValue.value);

  const { range, setRange } = useRangeParams(props.disableUrlQueryParams);

  const rangeValue = range
    ? range.type === 'absolute'
      ? (range as DateRangePickerProps.AbsoluteValue)
      : range.type === 'relative'
      ? (range as DateRangePickerProps.RelativeValue)
      : null
    : null;

  const acceptSeries = filterSeries(
    groupSeries(props.acceptSeries, groupingValue.value),
    rangeValue,
  );
  const rejectSeries = filterSeries(
    groupSeries(props.rejectSeries, groupingValue.value),
    rangeValue,
  );

  const filters = (
    <SpaceBetween direction="horizontal" size="l">
      <SpaceBetween direction="vertical" size="xxs">
        <Box variant="strong">Select detail</Box>
        <Select
          options={groupingOptions}
          selectedAriaLabel="Selected"
          selectedOption={groupingValue}
          onChange={({ detail }) => setGrouping(detail.selectedOption)}
        />
      </SpaceBetween>
      <SpaceBetween direction="vertical" size="xxs">
        <Box variant="strong">Filter range</Box>
        <DateRangePicker
          i18nStrings={{
            todayAriaLabel: 'Today',
            nextMonthAriaLabel: 'Next month',
            previousMonthAriaLabel: 'Previous month',
            customRelativeRangeDurationLabel: 'Duration',
            customRelativeRangeDurationPlaceholder: 'Enter duration',
            customRelativeRangeOptionLabel: 'Custom range',
            customRelativeRangeOptionDescription: 'Set a custom range in the past',
            customRelativeRangeUnitLabel: 'Unit of time',
            formatRelativeRange: (e) => {
              const n = e.amount === 1 ? e.unit : `${e.unit}s`;

              return `Last ${e.amount} ${n}`;
            },
            formatUnit: (e, n) => (n === 1 ? e : `${e}s`),
            dateTimeConstraintText: 'For date, use YYYY/MM/DD.',
            relativeModeTitle: 'Relative range',
            absoluteModeTitle: 'Absolute range',
            relativeRangeSelectionHeading: 'Choose a range',
            startDateLabel: 'Start date',
            endDateLabel: 'End date',
            startTimeLabel: 'Start time',
            endTimeLabel: 'End time',
            clearButtonLabel: 'Clear and dismiss',
            cancelButtonLabel: 'Cancel',
            applyButtonLabel: 'Apply',
          }}
          isValidRange={isValidRange}
          placeholder="Filter by a date range"
          relativeOptions={getRelativeOptions()}
          value={rangeValue}
          dateOnly
          onChange={({ detail }) => setRange(detail.value)}
        />
      </SpaceBetween>
    </SpaceBetween>
  );

  const graph =
    graphType === 'bar' ? (
      <BarChart
        additionalFilters={filters}
        ariaLabel="Stacked bar chart"
        empty={
          <Box color="inherit" textAlign="center">
            <b>No data available</b>
            <Box color="inherit" variant="p">
              There is no data available
            </Box>
          </Box>
        }
        errorText="Error loading data."
        height={300}
        i18nStrings={{
          filterLabel: 'Filter displayed data',
          filterPlaceholder: 'Filter data',
          filterSelectedAriaLabel: 'selected',
          detailPopoverDismissAriaLabel: 'Dismiss',
          legendAriaLabel: 'Legend',
          chartAriaRoleDescription: 'bar chart',
          xTickFormatter: (e) => formatGroupedDate(e, groupingValue.value),
          yTickFormatter: (e) => e.toLocaleString('en-US'),
        }}
        loadingText="Loading chart"
        noMatch={
          <Box color="inherit" textAlign="center">
            <b>No matching data</b>
            <Box color="inherit" variant="p">
              There is no matching data to display
            </Box>
            <Button>Clear filter</Button>
          </Box>
        }
        recoveryText="Retry"
        series={[
          {
            title: 'Accept',
            type: 'bar',
            data: acceptSeries,
            valueFormatter: (e) => e.toLocaleString('en-US'),
          },
          {
            title: 'Reject',
            type: 'bar',
            data: rejectSeries,
            valueFormatter: (e) => e.toLocaleString('en-US'),
          },
        ]}
        xScaleType="categorical"
        xTitle={
          groupingValue
            ? groupingValue.value![0].toUpperCase() + groupingValue.value!.slice(1)
            : 'Day'
        }
        yTitle="Tweets"
        stackedBars
      />
    ) : (
      <AreaChart
        additionalFilters={filters}
        ariaLabel="Stacked area chart"
        empty={
          <Box color="inherit" textAlign="center">
            <b>No data available</b>
            <Box color="inherit" variant="p">
              There is no data available
            </Box>
          </Box>
        }
        errorText="Error loading data."
        height={300}
        i18nStrings={{
          filterLabel: 'Filter displayed data',
          filterPlaceholder: 'Filter data',
          filterSelectedAriaLabel: 'selected',
          detailPopoverDismissAriaLabel: 'Dismiss',
          legendAriaLabel: 'Legend',
          chartAriaRoleDescription: 'line chart',
          detailTotalLabel: 'Total',
          xTickFormatter: (e) => formatGroupedDate(e, groupingValue.value),
          yTickFormatter: (e) => e.toLocaleString('en-US'),
        }}
        loadingText="Loading chart"
        noMatch={
          <Box color="inherit" textAlign="center">
            <b>No matching data</b>
            <Box color="inherit" variant="p">
              There is no matching data to display
            </Box>
            <Button>Clear filter</Button>
          </Box>
        }
        recoveryText="Retry"
        series={[
          {
            title: 'Accept',
            type: 'area',
            data: acceptSeries,
            valueFormatter: (e) => e.toLocaleString('en-US'),
          },
          {
            title: 'Reject',
            type: 'area',
            data: rejectSeries,
            valueFormatter: (e) => e.toLocaleString('en-US'),
          },
        ]}
        xScaleType="time"
        xTitle={
          groupingValue
            ? groupingValue.value![0].toUpperCase() + groupingValue.value!.slice(1)
            : 'Day'
        }
        yTitle="Tweets"
      />
    );

  return <Container header={props.header}>{graph}</Container>;
}
