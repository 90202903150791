import {
  AppLayout,
  ContentLayout,
  Box,
  Container,
  Header,
  Link,
  SpaceBetween,
  Grid,
  Input,
  Button,
  ExpandableSection,
  TextContent,
} from '@cloudscape-design/components';
import { useState } from 'react';

import { LocalNavigation, Breadcrumbs, LocalNavigationProps } from '../navigation';
import useWindowDimensions, { NAV_WIDTH } from '../common/window';
import { useQuestionApi } from '../common/questions/api';
import { ChatMessage } from '../common/chat/types';
import { ChatBox } from '../components/chat/ChatBox';

export default function DissertationPage(props: LocalNavigationProps) {
  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Dissertation', href: '' }]} />}
      content={
        <ContentLayout>
          <div>
            <Container
              header={
                <Header
                  actions={
                    <Button
                      variant="normal"
                      onClick={() => {
                        window.open(
                          'https://cdn.maxnlp.ai/Weinzierl_Defense_Dissertation.pdf',
                          '_blank',
                        );
                      }}
                    >
                      Download
                    </Button>
                  }
                  description="Read my PhD dissertation here!"
                  variant="h1"
                >
                  Dissertation
                </Header>
              }
              variant="stacked"
            >
              <SpaceBetween direction="vertical" size="s">
                <Header description="Abstract" variant="h3">
                  Automatic Discovery of Frames of Communication and Their Stance Information
                </Header>
                <TextContent>
                  <Box variant="p">
                    The widespread usage of social media facilitates new avenues of research into
                    how communications are interpreted. While most natural language processing
                    research is focused on understanding the factual content of texts, less research
                    was devoted to the problems of text interpretation in a discourse. Researchers
                    in Communication Theory argue that discourse almost inescapably involves framing
                    - a conscious or subconscious highlighting of specific aspects of a topic,
                    guiding the audience towards a particular understanding. This dissertation
                    focuses on building automated methods for uncovering Frames of Communication
                    (FoCs) on social media, such that researchers can better understand how
                    information is framed, and thus, interpreted. More specifically, novel methods
                    are presented in this dissertation to perform (1) FoC discovery and articulation
                    in social media discourse; (2) detecting when FoCs are evoked throughout Social
                    Media Posts (SMPs), and (3) stance detection towards FoCs. Methods are presented
                    for performing these tasks across multiple topics, such as COVID-19 or HPV
                    vaccination hesitancy, immigration, abortion, or feminism, as well as
                    considering not only the texts of SMPs, but also their images. Multiple new
                    datasets of SMPs are introduced, having annotations of FoCs, their evocation, as
                    well as the stance of SMPs towards the evoked FoCs. Furthermore, specific
                    misinformation is studied that was propagated during the COVID-19 pandemic, with
                    a focus on how misinformation was framed on social media.
                  </Box>
                </TextContent>
                <ExpandableSection headerText="Full PDF">
                  <Box>
                    <iframe
                      height="1000px"
                      src="https://cdn.maxnlp.ai/Weinzierl_Defense_Dissertation.pdf"
                      width="100%"
                    />
                  </Box>
                </ExpandableSection>
              </SpaceBetween>
            </Container>
            <Container
              header={
                <Header description="Ask any question about my dissertation!" variant="h2">
                  Dissertation Q/A
                </Header>
              }
              variant="stacked"
            >
              <ChatBox />
            </Container>
          </div>
        </ContentLayout>
      }
      contentType="default"
      navigation={<LocalNavigation />}
      navigationOpen={props.navigationOpen}
      navigationWidth={NAV_WIDTH}
      toolsHide
      onNavigationChange={(event) => props.setNavigationOpen(event.detail.open)}
    />
  );
}
