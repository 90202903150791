import { useState } from 'react';
import {
  AppLayout,
  ContentLayout,
  Header,
  Grid,
  Box,
  Link,
  Container,
} from '@cloudscape-design/components';

import { LocalNavigation, Breadcrumbs, LocalNavigationProps } from '../navigation';
import { NAV_WIDTH } from '../common/window';

import { FramesGraph } from './graph';
import { DiscoveryHelp } from './help';

export default function FramesPage(props: LocalNavigationProps) {
  const [open, setOpen] = useState(true);

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Uncovering FoCs', href: '' }]} />}
      content={
        <ContentLayout
          header={
            <Container
              header={
                <Header
                  description="Uncovering Frames of Communication from Social Media Using Chain-of-Thought Reasoning"
                  variant="h1"
                >
                  Uncovering Frames of Communication
                </Header>
              }
              disableContentPaddings
            />
          }
        >
          <FramesGraph />
        </ContentLayout>
      }
      contentType="default"
      navigation={<LocalNavigation />}
      navigationOpen={props.navigationOpen}
      navigationWidth={NAV_WIDTH}
      tools={<DiscoveryHelp />}
      toolsOpen={open}
      toolsWidth={300}
      onNavigationChange={(event) => props.setNavigationOpen(event.detail.open)}
      onToolsChange={(event) => setOpen(event.detail.open)}
    />
  );
}
