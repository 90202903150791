import { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import '@cloudscape-design/global-styles/index.css';
import './global.css';

import Homepage from './home';
import Publications from './publications';
import Publication from './publications/publication';
import Projects from './projects';
import Experience from './experience';
import Education from './education';
import Error from './error';
import FramesPage from './frames';
import FramePage from './frames/frame';
import CategoryPage from './frames/categories/category';
import CategoryThemePage from './frames/categories/theme';
import CategoriesPage from './frames/categories';
import MoralityPage from './frames/moralities/morality';
import MoralitiesPage from './frames/moralities';
import QuestionPage from './frames/questions/question';
import QuestionsPage from './frames/questions';
import QuestionThemePage from './frames/questions/theme';
import Dissertation from './dissertation';
import FramesDiscoveryPage from './discovery';
import { FrameContext, FrameContextLayout, FrameProvider } from './common/data/context';

function App() {
  const { innerWidth: width } = window;
  const [navigationOpen, setNavigationOpen] = useState(width > 690);
  const { pathname, hash, key, search } = useLocation();

  useEffect(() => {
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    } else if (!search) {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <Routes>
      <Route
        element={<Error navigationOpen={navigationOpen} setNavigationOpen={setNavigationOpen} />}
        path="*"
      />
      <Route path="/">
        <Route
          element={
            <Homepage navigationOpen={navigationOpen} setNavigationOpen={setNavigationOpen} />
          }
          index
        />
        <Route
          element={
            <Dissertation navigationOpen={navigationOpen} setNavigationOpen={setNavigationOpen} />
          }
          path="dissertation"
        />
        <Route path="publications">
          <Route
            element={
              <Publications navigationOpen={navigationOpen} setNavigationOpen={setNavigationOpen} />
            }
            index
          />
          <Route
            element={
              <Publication navigationOpen={navigationOpen} setNavigationOpen={setNavigationOpen} />
            }
            path=":pubId"
          />
        </Route>
        <Route
          element={
            <Projects navigationOpen={navigationOpen} setNavigationOpen={setNavigationOpen} />
          }
          path="projects"
        />
        <Route
          element={
            <Experience navigationOpen={navigationOpen} setNavigationOpen={setNavigationOpen} />
          }
          path="experience"
        />
        <Route
          element={
            <Education navigationOpen={navigationOpen} setNavigationOpen={setNavigationOpen} />
          }
          path="education"
        />
        <Route element={<FrameContextLayout />}>
          <Route path="frames">
            <Route
              element={
                <FramesPage navigationOpen={navigationOpen} setNavigationOpen={setNavigationOpen} />
              }
              index
            />
            <Route path="categories">
              <Route
                element={
                  <CategoriesPage
                    navigationOpen={navigationOpen}
                    setNavigationOpen={setNavigationOpen}
                  />
                }
                index
              />
              <Route
                element={
                  <CategoryThemePage
                    navigationOpen={navigationOpen}
                    setNavigationOpen={setNavigationOpen}
                  />
                }
                path="themes/:themeId"
              />
              <Route
                element={
                  <CategoryPage
                    navigationOpen={navigationOpen}
                    setNavigationOpen={setNavigationOpen}
                  />
                }
                path=":categoryId"
              />
            </Route>
            <Route path="moralities">
              <Route
                element={
                  <MoralitiesPage
                    navigationOpen={navigationOpen}
                    setNavigationOpen={setNavigationOpen}
                  />
                }
                index
              />
              <Route
                element={
                  <MoralityPage
                    navigationOpen={navigationOpen}
                    setNavigationOpen={setNavigationOpen}
                  />
                }
                path=":moralityId"
              />
            </Route>
            <Route path="questions">
              <Route
                element={
                  <QuestionsPage
                    navigationOpen={navigationOpen}
                    setNavigationOpen={setNavigationOpen}
                  />
                }
                index
              />
              <Route
                element={
                  <QuestionThemePage
                    navigationOpen={navigationOpen}
                    setNavigationOpen={setNavigationOpen}
                  />
                }
                path="themes/:themeId"
              />
              <Route
                element={
                  <QuestionPage
                    navigationOpen={navigationOpen}
                    setNavigationOpen={setNavigationOpen}
                  />
                }
                path=":questionId"
              />
            </Route>
            <Route
              element={
                <FramePage navigationOpen={navigationOpen} setNavigationOpen={setNavigationOpen} />
              }
              path=":frameId"
            />
          </Route>
        </Route>
        <Route path="discovery">
          <Route
            element={
              <FramesDiscoveryPage
                navigationOpen={navigationOpen}
                setNavigationOpen={setNavigationOpen}
              />
            }
            index
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
