import { Link } from '@cloudscape-design/components';

import { DirectionsMessage, SearchItem, SearchMessage } from '../../common/chat/types';

export type DirectionsMapProps = {
  message: DirectionsMessage;
};

export function DirectionsMap(props: DirectionsMapProps) {
  const { origin, destination, mode, directions } = props.message;

  const duration = directions.legs[0].duration.text;
  const distance = directions.legs[0].distance.text;
  const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=${mode}`;

  return (
    <div>
      <Link href={url} target="_blank" variant="primary">
        <p className="text-gray-300 text-base flex items-center space-x-1 font-bold">
          {/* <Search /> TODO good icon here of a map */}
          <span>
            {duration} ({distance}) via {directions.summary}
          </span>
        </p>
        <p className="text-gray-400 text-sm flex items-center space-x-1">
          <span>
            {origin} to {destination} ({mode})
          </span>
        </p>
      </Link>
    </div>
  );
}
