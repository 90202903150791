import { Box, Container } from '@cloudscape-design/components';
import React from 'react';
import { SizeMe } from 'react-sizeme';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Macro, String as StringNode, Environment } from '@unified-latex/unified-latex-types';

import { visitGroupChild } from './parsing';
import { ContentSections } from './content';

export function createFigure(
  environment: Environment,
  figure: TexFigure,
  path: string,
  root: ContentSections,
) {
  for (const child of environment.content) {
    // TODO consider other macros like centering and small
    if (child.type === 'macro') {
      const macro = child as Macro;

      if (macro.content === 'caption') {
        // TODO track caption
        const groupElements: React.ReactNode[] = [];
        const children = macro.args![macro.args!.length - 1].content;

        for (const child of children) {
          visitGroupChild(child, groupElements, root);
        }
        figure.setCaption(groupElements);
      } else if (macro.content === 'label') {
        const labelContent = macro.args![macro.args!.length - 1].content;
        const labelRef = labelContent
          .map((x) => x as StringNode)
          .map((x) => x.content)
          .join('');
        figure.setLabel(labelRef);
      } else if (macro.content === 'includegraphics') {
        const pathContent = macro
          .args![macro.args!.length - 1].content.map((x) => (x as StringNode).content)
          .join('');
        const figPath = `${path}/${pathContent}`;
        figure.setPath(figPath);
      } else {
        // console.log('SKIPPED FIGURE ENVIRONMENT MACRO');
        // console.log(macro);
      }
    } else if (child.type === 'parbreak' || child.type === 'whitespace') {
      // nothing to do here
    } else {
      console.error('UNKNOWN FIGURE CHILD', child);
    }
  }
}

export class TexFigure {
  figureId: number;
  caption: React.ReactNode[];
  label: string;
  path: string;

  constructor({ figureId }: { figureId: number }) {
    this.figureId = figureId;
    this.caption = [];
    this.label = '';
    this.path = '';
  }
  setLabel(label: string) {
    this.label = label;
  }

  setPath(path: string) {
    this.path = path;
  }

  setCaption(caption: React.ReactNode[]) {
    this.caption = caption;
  }

  render() {
    const caption = (
      <Box
        children={[
          <Box key={`figure-${this.figureId}-caption-name`} fontWeight="bold" variant="span">
            Figure {this.figureId}:{' '}
          </Box>,
          ...this.caption,
        ]}
        key={`figure-${this.figureId}-caption`}
        textAlign="center"
        variant="p"
      />
    );

    let content: React.ReactNode = undefined;

    if (this.path.endsWith('.pdf')) {
      content = (
        <SizeMe key={`figure-${this.figureId}-space-resize`} refreshRate={32}>
          {({ size }) => (
            <Document key={`figure-${this.figureId}-doc`} file={this.path}>
              <Page
                key={`figure-${this.figureId}-doc-page`}
                pageNumber={1}
                width={size.width ?? undefined}
              />
            </Document>
          )}
        </SizeMe>
      );
    } else if (
      this.path.endsWith('.png') ||
      this.path.endsWith('.jpg') ||
      this.path.endsWith('.jpeg')
    ) {
      content = (
        <img
          key={`figure-${this.figureId}-img`}
          alt={`Figure ${this.figureId}`}
          src={this.path}
          width="100%"
        />
      );
    } else {
      console.error('Unknown figure type: ', this.path);
    }

    return (
      <Container key={this.label} footer={caption} id={this.label}>
        {content}
      </Container>
    );
  }
}
