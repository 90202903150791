import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export type ScrollingListProps = {
  children: React.ReactNode[];
};

export function ScrollingList(props: ScrollingListProps) {
  return (
    <Swiper
      modules={[Pagination, Navigation]}
      pagination={{ clickable: true }}
      slidesPerView={1}
      spaceBetween={50}
      navigation
    >
      {props.children.map((client, index) => (
        <SwiperSlide key={`swiper-${index}-slide`}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '500px',
            }}
          >
            {client}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
