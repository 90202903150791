import { Box, Container, Header, ProgressBar, SpaceBetween } from '@cloudscape-design/components';
import { parse } from '@unified-latex/unified-latex-util-parse';
import { useAsync } from 'react-use';
import { parseBibFile } from 'bibtex';
import { useState } from 'react';

import { Publication } from '../../common/publications';

import { buildContent } from './build';
import { ContentSections } from './content';

import './index.css';
import 'katex/dist/katex.min.css';

function preprocess(text: string) {
  return text.replace('``', '“').replace('"', '”');
}

export function useBibTex({ path }: { path: string }) {
  const [progress, setProgress] = useState<number>(0);
  const state = useAsync(async () => {
    const response = await fetch(`${path}/main.tex`);

    if (response.status !== 200) {
      throw new Error(`Could not find ${path}`);
    }
    setProgress(40);

    const texText = await response.text();

    if (texText.startsWith('<!DOCTYPE html>')) {
      throw new Error(`Could not find ${path}`);
    }
    setProgress(50);

    const texPreprocess = preprocess(texText);
    const texParse = parse(texPreprocess);

    const bibResponse = await fetch(`${path}/main.bib`);

    if (response.status !== 200) {
      throw new Error(`Could not find ${path} BibTeX`);
    }
    setProgress(80);

    const bibText = await bibResponse.text();

    setProgress(90);

    if (bibText.startsWith('<!DOCTYPE html>')) {
      throw new Error(`Could not find ${path} BibTeX`);
    }

    const bibParse = parseBibFile(bibText).entries_raw;

    setProgress(100);

    const bibPubs: Publication[] = bibParse.map((b) => {
      return {
        key: b._id.toLowerCase(),
        type: b.type,
        fields: b.fields,
      };
    });

    return {
      tex: texParse.content,
      bib: bibPubs,
    };
  });

  let content: ContentSections | undefined = undefined;
  let bodyContent: React.ReactNode = undefined;

  if (state.value) {
    // first pass to extract references, citations, etc
    const refPass = buildContent(state.value.tex, path, state.value.bib);
    content = buildContent(state.value.tex, path, state.value.bib, refPass.citationNumber);
    const contentItems = content.render();
    bodyContent = <SpaceBetween children={contentItems} direction="vertical" size="xs" />;
  }

  let header: React.ReactNode = undefined;

  if (state.loading || state.error) {
    header = (
      <ProgressBar
        additionalInfo={
          state.error ? state.error.message : 'Loading LaTeX & BiBTeX files, figures, and tables...'
        }
        description={state.error ? 'Error loading paper' : 'Loading paper'}
        label={state.error ? 'Error Loading' : 'Loading'}
        status={state.error ? 'error' : 'in-progress'}
        value={progress}
      />
    );
  } else {
    header = (
      <Container>
        <Header variant="h2">
          {content!.title}
          <SpaceBetween
            children={[
              <Box
                key="content-header-authors"
                color="inherit"
                fontSize="heading-m"
                variant="small"
              >
                {content!.authors.join(', ')}
              </Box>,
              ...content!.affiliations.map((aline, aidx) => {
                return (
                  <Box
                    key={`content-header-affiliations-${aidx + 1}`}
                    color="inherit"
                    fontSize="body-m"
                    variant="small"
                  >
                    {aline}
                  </Box>
                );
              }),
            ]}
            key="content-header-authors-space"
            direction="vertical"
            size="xxxs"
          />
        </Header>
      </Container>
    );
  }

  return {
    header,
    body: bodyContent,
  };
}
