import {
  AppLayout,
  ContentLayout,
  Box,
  Container,
  Header,
  Link,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, LocalNavigationProps } from '../navigation';
import { NAV_WIDTH } from '../common/window';

export default function Error(props: LocalNavigationProps) {
  const navigate = useNavigate();

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Error', href: '' }]} />}
      content={
        <ContentLayout header={<Header variant="h1">404 Not Found</Header>}>
          <Container header={<Header variant="h2">Oops! We've Hit a Dead End</Header>}>
            <Box variant="p">
              It appears that the page you are looking for does not exist. Please check the URL and
              try again.
            </Box>
            <Link
              href="/"
              variant="secondary"
              onFollow={(event) => {
                event.preventDefault();
                navigate('/');
              }}
            >
              Back to Home
            </Link>
          </Container>
        </ContentLayout>
      }
      contentType="default"
      navigation={<LocalNavigation />}
      navigationOpen={props.navigationOpen}
      navigationWidth={NAV_WIDTH}
      toolsHide
      onNavigationChange={(event) => props.setNavigationOpen(event.detail.open)}
    />
  );
}
