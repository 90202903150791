import { AsyncStateRetry } from 'react-use/lib/useAsyncRetry';
import { createContext, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { useData } from './raw';
import { Frame, FrameMeasurement, Question, QuestionTheme, Theme } from './types';
import { FrameData, processData } from './frames';

export type FrameContextType = {
  state: AsyncStateRetry<FrameData>;
};

export const FrameContext = createContext<FrameContextType>({
  state: {
    loading: true,
    retry: () => {
      //
      return;
    },
  },
});

export const FrameProvider = ({ children }: { children: React.ReactNode }) => {
  const state = useFramesData();

  return <FrameContext.Provider value={{ state }}>{children}</FrameContext.Provider>;
};

export const FrameContextLayout = () => {
  return (
    <FrameProvider>
      <Outlet />
    </FrameProvider>
  );
};

export function useFramesData(): AsyncStateRetry<FrameData> {
  const timeState = useData<FrameMeasurement[]>('frames/covid19-frame-predict-v3_stance-ts.json');
  const framesState = useData<Frame[]>('frames/frames.json');
  const themesState = useData<Theme[]>('frames/themes.json');
  const questionsState = useData<Question[]>('frames/questions.json');
  const questionThemesState = useData<QuestionTheme[]>('frames/questionThemes.json');

  const value = useMemo(() => {
    if (
      !timeState.value ||
      !framesState.value ||
      !themesState.value ||
      !questionsState.value ||
      !questionThemesState.value
    ) {
      return undefined;
    }

    return processData(
      timeState.value,
      framesState.value,
      themesState.value,
      questionsState.value,
      questionThemesState.value,
    );
  }, [
    timeState.value,
    framesState.value,
    themesState.value,
    questionsState.value,
    questionThemesState.value,
  ]);

  const states = [timeState, framesState, themesState, questionsState, questionThemesState];

  const loading = states.some((state) => state.loading);

  if (loading || !value) {
    return {
      loading: true,
      retry: () => {
        //
        return;
      },
    };
  }

  const error = states.find((state) => state.error);

  if (error?.error) {
    return {
      loading: false,
      error: error.error,
      retry: error.retry,
    };
  }

  return {
    loading: false,
    retry: () => {
      ///
      return;
    },
    value,
  };
}
