import { parseISO, format } from 'date-fns';

import { EmailAddress, EmailMessage } from '../common/chat/types';

export function groupEmails(emails: EmailMessage[], folder: string) {
  const groupedEmails: Record<string, EmailMessage[]> = {};

  emails.forEach((email) => {
    let dateStr: string;

    switch (folder) {
      case 'inbox':
        dateStr = email.received!;
        break;
      case 'sentitems':
        dateStr = email.sent!;
        break;
      case 'drafts':
        dateStr = email.created!;
        break;
      default:
        return;
    }

    const date = format(parseISO(dateStr), 'yyyy-MM-dd');

    if (!groupedEmails[date]) {
      groupedEmails[date] = [];
    }
    groupedEmails[date].push(email);
  });

  return groupedEmails;
}

export function emailAddressToString(address?: EmailAddress, defaultEmail?: EmailAddress): string {
  if (address) {
    return address.name ? `${address.name} <${address.address}>` : address.address;
  } else if (defaultEmail) {
    return defaultEmail.name
      ? `${defaultEmail.name} <${defaultEmail.address}>`
      : defaultEmail.address;
  }

  return 'Unknown';
}

export function emailAddressToStringShort(
  address?: EmailAddress,
  defaultEmail?: EmailAddress,
): string {
  if (address) {
    return address.name ? address.name : address.address;
  } else if (defaultEmail) {
    return defaultEmail.name ? defaultEmail.name : defaultEmail.address;
  }

  return 'Unknown';
}

export function getEmailTimestamp(email: EmailMessage, folder: string): Date | undefined {
  let timestamp: string;

  switch (folder) {
    case 'inbox':
      timestamp = email.received!;
      break;
    case 'sentitems':
      timestamp = email.sent!;
      break;
    case 'drafts':
      timestamp = email.created!;
      break;
    default:
      return undefined;
  }

  const date = parseISO(timestamp);

  return date;
}

export function formatEmailTimestamp(email: EmailMessage, folder: string): string {
  let timestamp: string;
  let title: string;

  switch (folder) {
    case 'inbox':
      timestamp = email.received!;
      title = 'Received';
      break;
    case 'sentitems':
      timestamp = email.sent!;
      title = 'Sent';
      break;
    case 'drafts':
      timestamp = email.created!;
      title = 'Created';
      break;
    default:
      return '';
  }

  const date = parseISO(timestamp);

  return format(date, 'p');
}

export function formatEmailStatus(folder: string): string {
  switch (folder) {
    case 'inbox':
      return 'Received';
    case 'sentitems':
      return 'Sent';
    case 'drafts':
      return 'Created';
    default:
      return '';
  }
}

export function formatEmailStatusGuess(email: EmailMessage, userEmail: string): string {
  if (email.from_ && email.from_.address === userEmail) {
    return 'Received';
  } else if (email.from_) {
    return 'Sent';
  } else if (email.created) {
    return 'Created';
  }

  return '';
}
