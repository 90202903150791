import { parseISO, format } from 'date-fns';
import { createRef, useMemo, useState } from 'react';

import { CalendarEvent, CalendarEventListMessage } from '../../common/chat/types';
import { formatDayEventSpan, formatTitleRange, groupEvents } from '../../common/events';
import { Modal } from '../Modal';

export type CalendarEventListProps = {
  message: CalendarEventListMessage;
  maxEvents?: number;
};

export function CalendarEventList(props: CalendarEventListProps) {
  const { message, maxEvents = 3 } = props;
  const { start, end, events } = message;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const startDate = parseISO(start);
  const endDate = parseISO(end);

  // Group events by their start day
  const groupedEvents = useMemo(() => {
    return groupEvents(events, start, end);
  }, [events, start, end]);

  // Create a list of the first maxEvents events
  const limitedEvents = useMemo(() => {
    const result: CalendarEvent[] = [];
    let count = 0;
    const seenEvents: Set<string> = new Set();

    for (const date of Object.keys(groupedEvents)) {
      for (const event of groupedEvents[date]) {
        if (count >= maxEvents) {
          return result;
        }

        if (seenEvents.has(event.id)) {
          continue;
        }
        seenEvents.add(event.id);
        result.push(event);
        count++;
      }
    }

    return result;
  }, [groupedEvents, maxEvents]);

  const limitedGroupedEvents = useMemo(() => {
    const g = groupEvents(limitedEvents, start, end);
    const ds = Object.keys(g);
    const result: Record<string, CalendarEvent[]> = {};
    let count = 0;

    for (const date of ds) {
      for (const event of g[date]) {
        if (count >= maxEvents) {
          return result;
        }

        if (!result[date]) {
          result[date] = [];
        }
        result[date].push(event);
        count++;
      }
    }

    return result;
  }, [limitedEvents, start, end, maxEvents]);

  const renderEvents = (gEvents: Record<string, CalendarEvent[]>) => {
    const dates = Object.keys(gEvents);

    return (
      <>
        {dates.map((date) => {
          const eventsOnDate = gEvents[date];
          const formattedDate = format(parseISO(date), 'EEEE, MMM do');

          return (
            <div key={date} className="bg-gray-700 px-4 py-2 rounded-lg">
              <h3 className="text-base font-semibold mb-2">{formattedDate}</h3>
              {eventsOnDate.map((event) => (
                <div key={event.id} className="bg-gray-800 rounded-lg shadow-md py-2 px-4 mb-2">
                  <h4 className="text-base font-semibold">{event.subject}</h4>
                  <p className="text-gray-400">{formatDayEventSpan(event)}</p>
                  {event.location && (
                    <p className="event-location text-gray-400">{event.location}</p>
                  )}
                </div>
              ))}
            </div>
          );
        })}
      </>
    );
  };

  const scrollRef = createRef<HTMLDivElement>();

  return (
    <div className="text-gray-300">
      <h2 className="text-lg font-bold">Events on {formatTitleRange(startDate, endDate)}</h2>
      <div className="space-y-2 py-2">
        {events.length > 0 ? (
          <>
            {renderEvents(limitedGroupedEvents)}
            {events.length > maxEvents && (
              <p
                className="text-blue-500 hover:text-blue-300 cursor-pointer mt-2 m-x-auto text-center"
                onClick={() => setIsModalOpen(true)}
              >
                Show all events...
              </p>
            )}
          </>
        ) : (
          <p className="text-gray-400">No events found within the specified time range.</p>
        )}
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div>
          <h2 className="text-lg font-bold mb-4 px-4 pt-4 text-gray-300">
            Events on {formatTitleRange(startDate, endDate)}
          </h2>
        </div>
        <div
          ref={scrollRef}
          className="max-h-[80dvh] overflow-y-auto flex flex-col px-4 space-y-2 text-gray-300 pb-4"
        >
          {renderEvents(groupedEvents)}
        </div>
      </Modal>
    </div>
  );
}
