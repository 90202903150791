import { createRef, useMemo, useState } from 'react';
import { Icon } from '@cloudscape-design/components';

import { PeopleListMessage } from '../../common/chat/types';
import { Modal } from '../Modal';

export type PeopleSearchProps = {
  message: PeopleListMessage;
};
// export type ScoredEmailAddress = {
//   address: string;
//   relevanceScore: number;
// }
// export type Person = {
//   id?: string;
//   displayName?: string;
//   givenName?: string;
//   surname?: string;
//   jobTitle?: string;
//   companyName?: string;
//   emailAddresses: ScoredEmailAddress[];
//   userPrincipalName?: string;
//   imAddress?: string;
//   phones: string[];
// }
export function PeopleSearch(props: PeopleSearchProps) {
  const { people, display } = props.message;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const scrollRef = createRef<HTMLDivElement>();

  return (
    <div>
      <button className="outline-none" onClick={() => setIsModalOpen(true)}>
        <p className="text-gray-300 text-base flex items-center space-x-1">
          <Icon name="search" size="medium" variant="normal" />
          <span>Searched People</span>
        </p>
      </button>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div>
          <h2 className="text-lg font-bold px-4 pt-4 text-gray-300">Matching People</h2>
        </div>
        {people && people.length > 0 ? (
          <div
            ref={scrollRef}
            className="max-h-[80dvh] overflow-y-auto flex flex-col px-4 space-y-2 text-gray-300 pb-4"
          >
            {people.map((person, index) => (
              <div key={person.id} className="bg-gray-700 px-4 py-2 rounded-lg">
                <h3 className="text-base font-semibold mb-2">
                  {person.displayName && <span>{person.displayName}</span>}
                  {person.companyName && (
                    <span className="text-gray-400">
                      {' | '}
                      {person.companyName}
                    </span>
                  )}
                </h3>
                {person.phones && person.phones.length > 0 && (
                  <p className="text-gray-300">{person.phones.join(', ')}</p>
                )}
                {person.emailAddresses && person.emailAddresses.length > 0 && (
                  <p className="text-gray-100">
                    {person.emailAddresses.map((email) => email.address).join(', ')}
                  </p>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div>
            <p className="text-gray-400 mb-4 px-4">No matching people found</p>
          </div>
        )}
      </Modal>
    </div>
  );
}
