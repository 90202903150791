import { Header, Box, HelpPanel, Icon, Link } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

export function CategoriesHelp() {
  const navigate = useNavigate();

  return (
    <HelpPanel
      footer={
        <div>
          <Header variant="h3">
            Learn more <Icon name="external" />
          </Header>
          <ul>
            <li>
              <Link
                href="https://ojs.aaai.org/index.php/ICWSM/article/view/19360"
                target="_blank"
                external
              >
                A journey of stance, ontological commitments and moral foundations
              </Link>
            </li>
            <li>
              <Link
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9148945/"
                target="_blank"
                external
              >
                Identifying the framing of beliefs towards vaccine confidence in Twitter discourse
              </Link>
            </li>
          </ul>
        </div>
      }
      header={<Header variant="h2">About Categories</Header>}
    >
      <div>
        <Header variant="h3">What is a framing category?</Header>
        <Box variant="p">
          A framing category is a high-level grouping of frames which all share a common property.
          For example, the{' '}
          <Link
            href="/frames/categories/misinformation"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Misinformation
          </Link>{' '}
          category includes frames that are misinformation about the COVID-19 vaccine.
        </Box>
        <Header variant="h3">How were categories identified?</Header>
        <Box variant="p">
          These high-level categories were identified through a deliberation process by NLP
          researchers and Public Health experts after analyzing the frames resulting from the
          opinion-based Q/A framing discovery process. See{' '}
          <Link
            href="/frames"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Frames
          </Link>{' '}
          for more information.
        </Box>
        <Header variant="h3">How were framings categorized?</Header>
        <Box variant="p">
          Each discovered framing was categorized as to which of the five categories it belonged to,
          where a frame could belong to more than one category. This judgement process was performed
          by multiple NLP researchers and Public Health experts. Additionally, each frame was
          further assigned one (or more) theme for each category it belonged to. This approach
          produced a taxonomy of frames, which are organized into categories, themes, and then
          concerns (individual frames).
        </Box>
      </div>
    </HelpPanel>
  );
}
