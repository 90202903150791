import React, { useState, useEffect, Suspense } from 'react';
import { recorderControls } from 'react-audio-voice-recorder/dist/hooks/useAudioRecorder';

const LiveAudioVisualizer = React.lazy(async () => {
  const { LiveAudioVisualizer } = await import('react-audio-visualize');

  return { default: LiveAudioVisualizer };
});

export type ChatAudioRecorderProps = {
  /**
   * This gets called when the save button is clicked.
   * In case the recording is cancelled, the blob is discarded.
   **/
  onRecordingComplete?: (blob: Blob) => void;
  /**
   * Allows calling of hook outside this component. The controls returned by the hook can then be passed to the component using this prop.
   * This allows for use of hook methods and state outside this component
   * @sample_usage https://github.com/samhirtarif/react-audio-recorder#combine-the-useaudiorecorder-hook-and-the-audiorecorder-component
   **/
  recorderControls: recorderControls;
  /**
   * Displays a waveform visualization for the audio when set to `true`. Defaults to `false`
   **/
  showVisualizer?: boolean;
  loading?: boolean;
};

export function ChatAudioRecorder(props: ChatAudioRecorderProps) {
  const { onRecordingComplete, recorderControls, showVisualizer = false } = props;

  const {
    startRecording,
    stopRecording,
    recordingBlob,
    isRecording,
    recordingTime,
    mediaRecorder,
  } = recorderControls;

  const [shouldSave, setShouldSave] = useState(false);

  const stopAudioRecorder: (save?: boolean) => void = (save = true) => {
    setShouldSave(save);
    stopRecording();
  };

  useEffect(() => {
    if (shouldSave && recordingBlob != null && onRecordingComplete != null) {
      onRecordingComplete(recordingBlob);
    }
  }, [recordingBlob]);

  return (
    <div
      className={`${
        isRecording ? 'ease-out w-full bg-[#001833] p-2' : 'ease-in'
      } border-none rounded-3xl box-border text-white flex items-center transition-all duration-200`}
      data-testid="audio_recorder"
    >
      <div className="flex justify-between w-full items-center">
        <button
          className={`rounded-full w-10 h-10 items-center justify-center flex ${
            isRecording
              ? 'bg-[#002b5c] p-3 text-[#007aff]'
              : props.loading
              ? 'p-2 text-gray-400'
              : 'p-2 text-black'
          }`}
          disabled={props.loading}
          onClick={isRecording ? () => stopAudioRecorder(false) : startRecording}
        >
          {isRecording ? (
            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
                fill="currentColor"
              />
            </svg>
          ) : (
            <svg height="24" viewBox="0 0 470 470" width="24" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path
                  d="M235,302.296c47.177,0,85.423-38.245,85.423-85.423V85.423C320.423,38.245,282.177,0,235,0s-85.423,38.245-85.423,85.423
                  v131.451C149.577,264.051,187.823,302.296,235,302.296z"
                  fill="currentColor"
                />
                <path
                  d="M350.423,136.148v30h15v50.726c0,71.915-58.508,130.423-130.423,130.423s-130.423-58.507-130.423-130.423v-50.726h15v-30
                  h-45v80.726C74.577,300.273,138.551,369,220,376.589V440h-90.444v30h210.889v-30H250v-63.411
                  c81.449-7.589,145.423-76.317,145.423-159.716v-80.726H350.423z"
                  fill="currentColor"
                />
              </g>
            </svg>
          )}
        </button>
        <span
          className={`${!isRecording ? 'hidden' : ''} ml-2 text-sm leading-none`}
          data-testid="ar_timer"
        >
          {Math.floor(recordingTime / 60)}:{String(recordingTime % 60).padStart(2, '0')}
        </span>
        {showVisualizer ? (
          <span className={`${!isRecording ? 'hidden' : ''} ml-2 flex items-center flex-grow`}>
            {mediaRecorder && (
              <Suspense fallback={<></>}>
                <LiveAudioVisualizer
                  barWidth={4}
                  fftSize={512}
                  gap={4}
                  height={30}
                  maxDecibels={-10}
                  mediaRecorder={mediaRecorder}
                  minDecibels={-90}
                  smoothingTimeConstant={0.4}
                />
              </Suspense>
            )}
          </span>
        ) : (
          <span
            className={`${
              !isRecording ? 'hidden' : ''
            } ml-2 flex items-baseline flex-grow animate-pulse`}
          >
            <span className="chat-audio-recorder-status-dot bg-red-600 rounded-full h-2.5 w-2.5 mr-1" />
            Recording
          </span>
        )}
      </div>
      <button
        className={`rounded-full w-10 h-10 items-center justify-center flex ${
          isRecording ? 'bg-[#002b5c] p-1 text-[#007aff]' : 'hidden'
        }`}
        onClick={() => stopAudioRecorder()}
      >
        <svg
          clipRule="evenodd"
          fillRule="evenodd"
          height="24"
          strokeLinejoin="round"
          strokeMiterlimit="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m2.25 12.321 7.27 6.491c.143.127.321.19.499.19.206 0 .41-.084.559-.249l11.23-12.501c.129-.143.192-.321.192-.5 0-.419-.338-.75-.749-.75-.206 0-.411.084-.559.249l-10.731 11.945-6.711-5.994c-.144-.127-.322-.19-.5-.19-.417 0-.75.336-.75.749 0 .206.084.412.25.56"
            fill="currentColor"
            fillRule="nonzero"
          />
        </svg>
      </button>
    </div>
  );
}
