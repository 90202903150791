import { Container, SpaceBetween } from '@cloudscape-design/components';
import Network from 'react-graph-vis';

import { Frame, FrameRelation } from './frames';

import './graph.css';
import { useData } from '../common/data/raw';
import { EmptyState, ErrorState, LoadingState } from '../common/data/loading';

function getTitle(frame: Frame) {
  const p_str = frame.problems
    .map((x) =>
      x
        .split(' ')
        .map((y) => y[0].toUpperCase() + y.slice(1))
        .join(' '),
    )
    .join(', ');

  return [`${frame.f_id} (${p_str}): ${frame.count} tweets`, frame.text].join('\n');
}

function getRelTitle(rel: FrameRelation) {
  if (rel.type === 'contradicts') {
    return [`${rel.x} contradicts ${rel.y}:`, rel.reasoning].join('\n');
  }

  return [`${rel.x} is more specific than ${rel.y}:`, rel.reasoning].join('\n');
}

function getColor(frame: Frame) {
  const p = frame.problems.join(',');

  if (p === 'confidence') {
    return '#0CE464';
  } else if (p === 'conspiracy') {
    return '#F88A72';
  } else if (p === 'complacency') {
    return '#0CE4C3';
  } else if (p === 'calculation') {
    return '#CDCE75';
  } else if (p === 'collective') {
    return '#83A4EC';
  } else if (p === 'compliance') {
    return '#EAC47A';
  } else if (p === 'constraints') {
    return '#C183EC';
  } else if (p === 'other') {
    return '#B7B7B7';
  } else if (p === 'confidence,conspiracy') {
    return '#A27B3A';
  } else if (p === 'confidence,collective') {
    return '#59A9A9';
  } else if (p === 'complacency,calculation') {
    return '#94E2D5';
  }

  return '#000000';
}

export function FramesGraph() {
  const frameState = useData<Frame[]>('discovery/frames.json');
  const relationState = useData<FrameRelation[]>('discovery/frameRelations.json');

  const states = [frameState, relationState];

  const loading = states.some((state) => state.loading);

  if (loading) {
    return <LoadingState />;
  }

  const error = states.find((state) => state.error);

  if (error?.error) {
    return (
      <SpaceBetween direction="vertical" size="xxs">
        <EmptyState />
        <ErrorState error={error.error} onRetry={error.retry} />
      </SpaceBetween>
    );
  }

  const [frames, relations] = [frameState.value!, relationState.value!];

  return (
    <div className="vis-container">
      <Network
        graph={{
          nodes: frames.map((frame) => {
            return {
              id: frame.f_id,
              label: frame.f_id,
              title: getTitle(frame),
              color: getColor(frame),
              value: frame.count,
            };
          }),
          edges: relations.map((relation) => {
            if (relation.type === 'contradicts') {
              return {
                from: relation.x,
                to: relation.y,
                color: '#DB1F48',
                arrows: { from: false, to: false },
                dashes: true,
                label: 'Contradicts',
                title: getRelTitle(relation),
              };
            }

            return {
              from: relation.x,
              to: relation.y,
              color: '#18A558',
              arrows: 'to',
              dashes: false,
              label: 'Specializes',
              title: getRelTitle(relation),
            };
          }),
        }}
        options={{
          autoResize: true,
          width: '100%',
          height: '100%',
          configure: {
            enabled: false,
          },
          interaction: {
            hover: true,
            tooltipDelay: 100,
          },
          nodes: {
            shape: 'dot',
          },
          layout: {
            hierarchical: false,
            randomSeed: 1,
            improvedLayout: false,
          },
          physics: {
            barnesHut: {
              theta: 0.5,
              gravitationalConstant: -20000,
              centralGravity: 2.0,
              springLength: 40,
              springConstant: 0.03,
              damping: 0.2,
              avoidOverlap: 0.5,
            },
            stabilization: {
              enabled: true,
              iterations: 300,
              updateInterval: 50,
            },
          },
        }}
      />
    </div>
  );
}
