import { useState } from 'react';
import { PropertyFilterProps } from '@cloudscape-design/components/property-filter';
import { SelectProps } from '@cloudscape-design/components/select';
import { StringParam, useQueryParams, QueryParamConfig } from 'use-query-params';
import queryString from 'query-string';

export const TokenParam: QueryParamConfig<
  ReadonlyArray<PropertyFilterProps.Token>,
  ReadonlyArray<PropertyFilterProps.Token>
> = {
  encode(value: ReadonlyArray<PropertyFilterProps.Token>) {
    const valueStr = queryString.stringify({
      k: value.map((token) => token.propertyKey).join(','),
      o: value.map((token) => token.operator).join(','),
      v: value.map((token) => token.value).join(','),
    });

    return valueStr;
  },

  decode(strValue: string | (string | null)[] | null | undefined) {
    let parseValue = strValue;

    if (Array.isArray(parseValue)) {
      parseValue = parseValue[0];
    }

    if (parseValue === null || parseValue === undefined) {
      return [];
    }

    if (parseValue === '') {
      return [];
    }

    const valueObj = queryString.parse(parseValue);
    const propertyKeys = (valueObj.k as string).split(',');
    const operators = (valueObj.o as string).split(',');
    const values = (valueObj.v as string).split(',');

    if (propertyKeys.length !== operators.length || operators.length !== values.length) {
      return [];
    }

    if (propertyKeys.length === 0) {
      return [];
    }

    if (propertyKeys.length === 1 && propertyKeys[0] === '') {
      return [];
    }

    const results: ReadonlyArray<PropertyFilterProps.Token> = propertyKeys.map((propertyKey, i) => {
      const token: PropertyFilterProps.Token = {
        propertyKey,
        operator: operators[i] as PropertyFilterProps.ComparisonOperator,
        value: values[i],
      };

      return token;
    });

    return results;
  },
};

export const OperationParam: QueryParamConfig<
  PropertyFilterProps.JoinOperation,
  PropertyFilterProps.JoinOperation
> = {
  encode(value: PropertyFilterProps.JoinOperation) {
    return value;
  },

  decode(strValue: string | (string | null)[] | null | undefined) {
    if (strValue === 'or') {
      return 'or';
    }

    return 'and';
  },
};

export function useFilterSortParams(disableUrlQueryParams?: boolean) {
  const [query, setQuery] = useQueryParams({
    tokens: TokenParam,
    operation: OperationParam,
  });
  const [selectedOption, setSelectedOption] = useQueryParams({
    label: StringParam,
    value: StringParam,
  }); // label: "Default Sorting", value: "default"

  const [localQuery, localSetQuery] = useState<PropertyFilterProps.Query>({
    tokens: [],
    operation: 'and',
  });
  const [localSelectedOption, localSetSelectedOption] = useState<SelectProps.Option>({
    label: 'Default Sorting',
    value: 'default',
  });

  if (disableUrlQueryParams) {
    return {
      query: localQuery,
      setQuery: localSetQuery,
      selectedOption: localSelectedOption,
      setSelectedOption: localSetSelectedOption,
    };
  }

  return { query, setQuery, selectedOption, setSelectedOption };
}
