import { Icon } from '@cloudscape-design/components';
import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
};

export function Modal({ isOpen, onClose, children }: ModalProps) {
  if (!isOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50"
      onClick={handleOverlayClick}
    >
      <div className="bg-gray-900 rounded-lg max-w-3xl w-full relative">
        <button
          className="absolute top-2 right-2 text-gray-200 hover:text-gray-100 text-xs"
          onClick={onClose}
        >
          <Icon name="close" size="medium" variant="normal" />
        </button>
        <div className="mt-4">{children}</div>
      </div>
    </div>,
    document.body,
  );
}
