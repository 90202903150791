import { format } from 'date-fns';

import { UpdateEmailMessage } from '../../common/chat/types';
import { emailAddressToString, formatEmailStatus, getEmailTimestamp } from '../../common/emails';

export type UpdateEmailProps = {
  message: UpdateEmailMessage;
};

export function UpdateEmail(props: UpdateEmailProps) {
  const { old, current } = props.message;
  // const { user } = useAuth0();

  const currentTimestamp = getEmailTimestamp(current, 'drafts');
  const userAddress = undefined;

  return (
    <div className="space-y-2 text-gray-300">
      <h2 className="text-base font-bold text-blue-500">Email Draft Updated</h2>
      <div className="bg-gray-800 rounded-lg shadow-md py-2 px-4 border border-blue-500">
        <h3 className="text-base font-semibold">
          {old.subject !== current.subject ? (
            <>
              <span className="line-through">{old.subject}</span>{' '}
              <span>
                {'-> '}
                {current.subject}
              </span>
            </>
          ) : (
            current.subject
          )}
        </h3>
        <div>
          <p className="text-gray-400">
            From:{' '}
            {emailAddressToString(old.from_, userAddress) !==
            emailAddressToString(current.from_, userAddress) ? (
              <>
                <span className="line-through">{emailAddressToString(old.from_, userAddress)}</span>{' '}
                <span>
                  {'-> '}
                  {emailAddressToString(current.from_, userAddress)}
                </span>
              </>
            ) : (
              emailAddressToString(current.from_, userAddress)
            )}
          </p>
          <p className="text-gray-400">
            To:{' '}
            {old.to.map((a) => emailAddressToString(a)).join(', ') !==
            current.to.map((a) => emailAddressToString(a)).join(', ') ? (
              <>
                <span className="line-through">
                  {old.to.map((a) => emailAddressToString(a)).join(', ')}
                </span>{' '}
                <span>
                  {'-> '}
                  {current.to.map((a) => emailAddressToString(a)).join(', ')}
                </span>
              </>
            ) : (
              current.to.map((a) => emailAddressToString(a)).join(', ')
            )}
          </p>
          {old.cc &&
            current.cc &&
            old.cc.map((a) => emailAddressToString(a)).join(', ') !==
              current.cc.map((a) => emailAddressToString(a)).join(', ') && (
              <p className="text-gray-400">
                CC:{' '}
                <span className="line-through">
                  {old.cc.map((a) => emailAddressToString(a)).join(', ')}
                </span>{' '}
                <span>
                  {'-> '}
                  {current.cc.map((a) => emailAddressToString(a)).join(', ')}
                </span>
              </p>
            )}
          {old.cc &&
            current.cc &&
            old.cc.map((a) => emailAddressToString(a)).join(', ') ===
              current.cc.map((a) => emailAddressToString(a)).join(', ') &&
            (old.cc.length > 0 || current.cc.length > 0) && (
              <p className="text-gray-400">
                CC: {current.cc.map((a) => emailAddressToString(a)).join(', ')}
              </p>
            )}
          {old.bcc &&
            current.bcc &&
            old.bcc.map((a) => emailAddressToString(a)).join(', ') !==
              current.bcc.map((a) => emailAddressToString(a)).join(', ') && (
              <p className="text-gray-400">
                BCC:{' '}
                <span className="line-through">
                  {old.bcc.map((a) => emailAddressToString(a)).join(', ')}
                </span>{' '}
                <span>
                  {'-> '}
                  {current.bcc.map((a) => emailAddressToString(a)).join(', ')}
                </span>
              </p>
            )}
          {old.bcc &&
            current.bcc &&
            old.bcc.map((a) => emailAddressToString(a)).join(', ') ===
              current.bcc.map((a) => emailAddressToString(a)).join(', ') &&
            (old.bcc.length > 0 || current.bcc.length > 0) && (
              <p className="text-gray-400">
                BCC: {current.bcc.map((a) => emailAddressToString(a)).join(', ')}
              </p>
            )}
          <p className="text-gray-400">
            {formatEmailStatus('drafts')}{' '}
            {currentTimestamp
              ? 'at ' +
                format(currentTimestamp, 'p') +
                ' on ' +
                format(currentTimestamp, 'MMMM do, yyyy')
              : ''}
          </p>
        </div>
        {old.body !== current.body && (
          <div>
            {old.body.split('\n').map((line, index) =>
              line === '' ? (
                <br key={index} />
              ) : (
                <p key={index} className="text-white line-through">
                  {line}
                </p>
              ),
            )}
            <p>{'-> '}</p>
            {current.body.split('\n').map((line, index) =>
              line === '' ? (
                <br key={index} />
              ) : (
                <p key={index} className="text-white">
                  {line}
                </p>
              ),
            )}
          </div>
        )}
        {old.body === current.body && <p className="text-white">{current.body}</p>}
      </div>
    </div>
  );
}
