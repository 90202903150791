import { Link } from '@cloudscape-design/components';

import { PlaceDetails, PlaceDetailsMessage } from '../../common/chat/types';
import 'swiper/swiper-bundle.css';
import { ScrollingList } from '../ScrollingList';

import { useState } from 'react';

import { Modal } from '../Modal';

export type PlaceDetailsInfoProps = {
  message: PlaceDetailsMessage;
};

export function PlaceDetailsInfo(props: PlaceDetailsInfoProps) {
  const { details } = props.message;

  const [weekdayOpen, setWeekdayOpen] = useState(false);
  const [photosOpen, setPhotosOpen] = useState(false);

  return (
    <div className="space-y-2 text-gray-300 px-2 py-2">
      <h2 className="text-base font-bold text-gray-300">{details.name}</h2>
      {details.address && (
        <Link
          className="text-blue-500 hover:text-blue-300"
          href={details.url}
          target="_blank"
          variant="secondary"
        >
          {details.address}
        </Link>
      )}
      <div className="flex space-x-4">
        {details.phone_number && (
          <Link
            className="text-blue-500 hover:text-blue-300"
            href={`tel:${details.phone_number}`}
            variant="secondary"
          >
            {details.phone_number}
          </Link>
        )}
        {details.website && (
          <Link
            className="text-blue-500 hover:text-blue-300"
            href={details.website}
            target="_blank"
            variant="secondary"
          >
            Visit Website
          </Link>
        )}
      </div>
      <p className="text-gray-400">
        {details.dine_in && 'Dine-in'}
        {details.takeout && ', Take-out'}
        {details.serves_vegetarian_food && (
          <>
            <span>, </span>
            <span className="text-green-500">Vegetarian Options</span>
          </>
        )}
      </p>
      <p className="text-gray-400">
        Rating: {details.rating ?? 'N/A'}
        {details.user_ratings_total && ` (${details.user_ratings_total} reviews)`}
        <span className="ml-2">·</span>
        {details.price_level && <span className="ml-2">{'$'.repeat(details.price_level)}</span>}
        {!!details.open_now !== undefined && (
          <span
            className={`text-gray-400 ml-2 ${details.open_now ? 'text-green-500' : 'text-red-500'}`}
          >
            {details.open_now ? 'Open now' : 'Closed'}
          </span>
        )}
        {details.weekday_text && (
          <span>
            <span className="ml-2 mr-2">·</span>
            <button className="outline-none" onClick={() => setWeekdayOpen(true)}>
              <p className="text-blue-500 hover:text-blue-300 cursor-pointer m-x-auto text-center text-base flex items-center space-x-1">
                <span>Hours</span>
              </p>
            </button>
            <Modal isOpen={weekdayOpen} onClose={() => setWeekdayOpen(false)}>
              <div>
                <h2 className="text-lg font-bold px-4 pt-4 text-gray-300">{details.name} Hours</h2>
              </div>
              <div className="max-h-[80dvh] overflow-y-auto flex flex-col px-4 space-y-2 text-gray-300 pb-4">
                <ul className="list-disc list-inside text-gray-400 text-sm space-y-1">
                  {details.weekday_text.map((text, index) => (
                    <li key={index}>{text}</li>
                  ))}
                </ul>
              </div>
            </Modal>
          </span>
        )}
      </p>
      {details.photos && details.photos.length > 0 && (
        <div className="space-y-1">
          <ScrollingList>
            {details.photos.map((photoUrl, index) => (
              <img
                key={index}
                alt={`${details.name} Photo ${index + 1}`}
                className="rounded-lg"
                src={photoUrl}
              />
            ))}
          </ScrollingList>
        </div>
      )}
    </div>
  );
}
