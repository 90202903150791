import {
  AppLayout,
  ContentLayout,
  Box,
  Container,
  Header,
  Link,
  SpaceBetween,
  Grid,
} from '@cloudscape-design/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faExternalLink } from '@fortawesome/free-solid-svg-icons';

import { LocalNavigation, Breadcrumbs, LocalNavigationProps } from '../navigation';

import Projects, { Project } from './projects';

import './index.css';
import useWindowDimensions, { NAV_WIDTH } from '../common/window';

export type ProjectItemProps = {
  project: Project;
  hideContent?: boolean;
};

export function ProjectItem(props: ProjectItemProps) {
  const { width } = useWindowDimensions();
  let hashIndex = window.location.href.indexOf('#');

  if (hashIndex === -1) {
    hashIndex = window.location.href.length;
  }

  const projectLink = window.location.href.slice(0, hashIndex) + '#' + props.project.projectId;
  const imageContent = <img alt={`Logo of ${props.project.title}`} src={props.project.logo} />;

  const content = props.project.content;

  const image = props.project.url ? (
    <Link href={props.project.url} target="_blank">
      {imageContent}
    </Link>
  ) : (
    imageContent
  );

  const header = (
    <Header
      description={
        <SpaceBetween direction="vertical" size="xxs">
          {props.project.subtitle}
          {!props.hideContent ? props.project.description : undefined}
        </SpaceBetween>
      }
      variant="h3"
    >
      <Box variant="h4">
        {props.project.title}
        <Box padding="xxs" variant="small">
          <SpaceBetween direction="horizontal" size="xxs">
            {!props.hideContent ? (
              <Link href={projectLink} variant="info">
                <FontAwesomeIcon icon={faLink} />
              </Link>
            ) : undefined}
            {props.project.url ? (
              <Link href={props.project.url} target="_blank" variant="info">
                <FontAwesomeIcon icon={faExternalLink} title="External project link" />
              </Link>
            ) : undefined}
          </SpaceBetween>
        </Box>
      </Box>
    </Header>
  );

  const title = (
    <Container
      key={props.project.projectId}
      disableContentPaddings={props.hideContent}
      header={header}
      id={props.project.projectId}
      media={{
        content: image,
        position: props.hideContent ? 'side' : width < 689 ? 'top' : 'side',
        width: '50%',
      }}
    />
  );

  return (
    <Container>
      <SpaceBetween direction="vertical" size="xs">
        {title}
        {content}
      </SpaceBetween>
    </Container>
  );
}

export type ProjectsListProps = {
  title?: string;
  hideContent?: boolean;
  hideYears?: boolean;
  actions?: React.ReactNode;
  projects: Project[];
};

export function ProjectsList(props: ProjectsListProps) {
  const projects: React.ReactNode[] = [];
  let currentYear: number | undefined = undefined;
  let yearIdx = 0;

  for (const project of props.projects) {
    if (currentYear !== project.year) {
      currentYear = project.year;

      if (!props.hideYears && yearIdx > 0) {
        projects.push(<Header variant="h2">{currentYear}</Header>);
      }
      yearIdx += 1;
    }

    projects.push(<ProjectItem hideContent={props.hideContent} project={project} />);
  }

  return props.title ? (
    <Container
      header={
        <Header actions={props.actions} variant="h2">
          {props.title}
        </Header>
      }
    >
      <SpaceBetween children={projects} direction="vertical" size="s" />
    </Container>
  ) : (
    <SpaceBetween children={projects} direction="vertical" size="s" />
  );
}

export default function ProjectsPage(props: LocalNavigationProps) {
  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Projects', href: '' }]} />}
      content={
        <ContentLayout
          header={
            <Container
              header={
                <Header description="My personal projects" variant="h1">
                  Projects
                </Header>
              }
              disableContentPaddings
            />
          }
        >
          <Grid gridDefinition={[{ colspan: { default: 12, s: 10 } }]}>
            <ProjectsList projects={Projects} />
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      navigation={<LocalNavigation />}
      navigationOpen={props.navigationOpen}
      navigationWidth={NAV_WIDTH}
      toolsHide
      onNavigationChange={(event) => props.setNavigationOpen(event.detail.open)}
    />
  );
}
