import { Container, Link, SpaceBetween, Table } from '@cloudscape-design/components';
import Select, { SelectProps } from '@cloudscape-design/components/select';
import { useNavigate } from 'react-router-dom';

import { QuestionInfo, QuestionTheme } from '../../common/data/types';
import { useFilterSortParams } from '../../common/filter/filter';

export type QuestionsTableProps = {
  questions: QuestionInfo[];
  questionThemeTextLookup: { [key: string]: QuestionTheme };
  disableUrlQueryParams?: boolean;
  header?: React.ReactNode;
};

function getOptions() {
  const options: SelectProps.Option[] = [
    { label: 'Default Sorting', value: 'default' },
    {
      label: 'Discovered Framings',
      value: 'discovered',
      description: 'Sort by number of discovered framings',
    },
    { label: 'Tweets', value: 'tweets', description: 'Sort by total Tweets' },
    { label: 'Most Accepted', value: 'accept', description: 'Sort by most Accepted' },
    { label: 'Most Reject', value: 'reject', description: 'Sort by most Rejected' },
  ];

  return options;
}

export function QuestionsTable(props: QuestionsTableProps) {
  const navigate = useNavigate();
  const { selectedOption, setSelectedOption } = useFilterSortParams(props.disableUrlQueryParams);
  const options = getOptions();

  const filteredInfo = [...props.questions];
  const sortedInfo = filteredInfo.sort((a, b) => {
    if (selectedOption.value === 'default') {
      return 0;
    } else if (selectedOption.value === 'tweets') {
      return b.total - a.total;
    } else if (selectedOption.value === 'accept') {
      return b.totalAccept / b.total < a.totalAccept / a.total ? -1 : 1;
    } else if (selectedOption.value === 'reject') {
      return b.totalReject / b.total < a.totalReject / a.total ? -1 : 1;
    } else if (selectedOption.value === 'discovered') {
      return b.frames.length < a.frames.length ? -1 : 1;
    }

    return 0;
  });

  return (
    <Container header={props.header}>
      <SpaceBetween size="m">
        <SpaceBetween direction="horizontal" size="s">
          <Select
            options={options}
            selectedAriaLabel="Selected"
            selectedOption={
              (selectedOption.label
                ? selectedOption
                : { label: 'Default Sorting', value: 'default' }) as SelectProps.Option
            }
            onChange={({ detail }) => setSelectedOption(detail.selectedOption)}
          />
        </SpaceBetween>
        <Table
          columnDefinitions={[
            {
              id: 'text',
              header: 'Question',
              cell: (item) => (
                <Link
                  href={`/frames/questions/${item.question.q_id}`}
                  variant="secondary"
                  onFollow={(event) => {
                    event.preventDefault();
                    navigate(event.detail.href!);
                  }}
                >
                  {item.question.q_text}
                </Link>
              ),
            },
            {
              id: 'theme',
              header: 'Theme',
              cell: (item) => (
                <Link
                  href={`/frames/questions/themes/${
                    props.questionThemeTextLookup[item.question.q_theme].theme_id
                  }`}
                  variant="secondary"
                  onFollow={(event) => {
                    event.preventDefault();
                    navigate(event.detail.href!);
                  }}
                >
                  {item.question.q_theme}
                </Link>
              ),
            },
            {
              id: 'discovered',
              header: 'Discovered Framings',
              cell: (item) => item.frames.length.toLocaleString('en-US'),
            },
            {
              id: 'tweets',
              header: 'Tweets',
              cell: (item) => item.total.toLocaleString('en-US'),
            },
          ]}
          items={sortedInfo}
          variant="embedded"
          wrapLines
        />
      </SpaceBetween>
    </Container>
  );
}
