import { Box, Container, Header, Link, SpaceBetween } from '@cloudscape-design/components';
import { Macro } from '@unified-latex/unified-latex-types';

import { Publication, PublicationsList } from '../publications';

import { TexFigure } from './figure';
import { TexTable } from './table';

export type BlockType = 'p' | 'block' | 'list' | 'figure' | 'table';

export class ContentBlock {
  readonly type: BlockType;
  contents: React.ReactNode[];
  constructor({ type, contents }: { type: BlockType; contents?: React.ReactNode[] }) {
    if (contents) {
      this.contents = contents;
    } else {
      this.contents = [];
    }
    this.type = type;
  }
}

export class ContentSection {
  readonly header?: React.ReactNode;
  readonly blocks: ContentBlock[];
  readonly footnotes: React.ReactNode[];
  label?: string;
  constructor({ header, blocks }: { header?: React.ReactNode; blocks?: ContentBlock[] }) {
    if (blocks) {
      this.blocks = blocks;
    } else {
      this.blocks = [];
    }
    this.header = header;
    this.footnotes = [];
  }
  pushFootnote(item: React.ReactNode) {
    this.footnotes.push(item);
  }
}

export class ContentSections {
  readonly sections: ContentSection[];
  readonly publications: { [key: string]: Publication };
  readonly tables: TexTable[];
  readonly figures: TexFigure[];
  readonly footnotes: React.ReactNode[];
  readonly equations: React.ReactNode[];
  readonly citationNumber: { [key: string]: string };
  readonly citations: Publication[];
  readonly appendices: ContentSection[];

  nodeId: number;
  openCitation?: string;
  abstract: React.ReactNode[];
  title: React.ReactNode[];
  authors: React.ReactNode[];
  openAffiliations: boolean;
  affiliations: React.ReactNode[];
  openAuthor: boolean;
  openAppendix: boolean;
  openLabel?: string;
  openMacro?: Macro;

  constructor({
    citations,
    citationNumber,
  }: {
    citations: Publication[];
    citationNumber?: { [key: string]: string };
  }) {
    this.sections = [];
    this.appendices = [];
    this.publications = {};
    this.citations = [];

    if (citationNumber) {
      this.citationNumber = citationNumber;
    } else {
      this.citationNumber = {};
    }

    for (const citation of citations) {
      // this.references[citation.key] = `citation-${citation.key}`;
      this.publications[citation.key] = citation;

      if (citation.key in this.citationNumber) {
        this.citations.push(citation);
      }
    }
    this.citations = this.citations.sort(
      (a, b) => parseInt(this.citationNumber[a.key]) - parseInt(this.citationNumber[b.key]),
    );
    this.tables = [];
    this.figures = [];
    this.footnotes = [];
    this.equations = [];
    this.nodeId = 0;
    this.openAffiliations = false;
    this.openAuthor = false;
    this.openAppendix = false;
    this.title = [];
    this.authors = [];
    this.affiliations = [];
    this.abstract = [];
  }
  pushLabel(label: string) {
    const section = this.sections[this.sections.length - 1];
    section.label = label;

    if (this.openAppendix) {
      this.citationNumber[section.label] = `${(this.appendices.length + 9)
        .toString(36)
        .toUpperCase()}`;
    } else {
      this.citationNumber[section.label] = `${this.sections.length}`;
    }
  }

  setAffiliations(content: React.ReactNode[]) {
    this.affiliations = content;
  }

  setAbstract(content: React.ReactNode[]) {
    this.abstract = content;
  }

  setTitle(content: React.ReactNode[]) {
    this.title = content;
  }
  setAuthors(authors: React.ReactNode[]) {
    this.authors = authors;
  }

  pushSection(item: ContentSection) {
    this.sections.push(item);
  }

  pushAppendix(item: ContentSection) {
    this.appendices.push(item);
    this.sections.push(item);
  }

  pushBlock(item: ContentBlock) {
    if (this.sections.length !== 0) {
      this.sections[this.sections.length - 1].blocks.push(item);
    }
  }

  pushContent(item: React.ReactNode) {
    if (this.sections.length !== 0) {
      const section = this.sections[this.sections.length - 1];

      if (section.blocks.length === 0) {
        section.blocks.push(
          new ContentBlock({
            contents: [item],
            type: 'p',
          }),
        );
      } else {
        const block = section.blocks[section.blocks.length - 1];

        if (block.type !== 'p') {
          section.blocks.push(
            new ContentBlock({
              contents: [item],
              type: 'p',
            }),
          );
        } else {
          block.contents.push(item);
        }
      }
    }
  }

  pushFootnote(items: React.ReactNode[]) {
    if (this.sections.length === 0) {
      console.error('Footnote without section');
    }

    const sectionIdx = this.sections.length - 1;
    const section = this.sections[sectionIdx];

    const refLinkText = `${section.footnotes.length + 1}`;
    const ref = `footnote-${sectionIdx}-${refLinkText}`;
    const refLink = (
      <Link
        key={`ref-${ref}-${++this.nodeId}`}
        variant="primary"
        onFollow={() => {
          const refNode = document.getElementById(ref);
          refNode?.scrollIntoView({
            block: 'center',
          });
        }}
      >
        <sup key={`ref-${ref}-${++this.nodeId}-sup`}>{refLinkText}</sup>
      </Link>
    );

    const footnote = (
      <Box
        children={[
          <Box key={`${ref}-num`} variant="strong">
            {refLinkText}:{' '}
          </Box>,
          ...items,
        ]}
        key={ref}
        id={ref}
        variant="p"
      />
    );

    this.pushContent(refLink);
    this.footnotes.push(footnote);
    section.pushFootnote(footnote);
  }

  getContents() {
    if (this.sections.length === 0) {
      return [];
    }

    const section = this.sections[this.sections.length - 1];

    if (section.blocks.length === 0) {
      this.pushBlock(
        new ContentBlock({
          contents: [],
          type: 'p',
        }),
      );
    }

    const block = section.blocks[section.blocks.length - 1];

    return block.contents;
  }

  render() {
    const content = this.sections.map((section, sectionIdx) => {
      const sectionId = sectionIdx + 1;
      const sectionKey = section.label ? section.label : `section-${sectionId}-header-root`;

      return (
        <Container
          key={`section-${sectionId}`}
          footer={
            section.footnotes.length > 0 ? (
              <SpaceBetween
                children={section.footnotes}
                key={`section-${sectionId}-footer`}
                direction="vertical"
                id={`section-${sectionId}-footer`}
                size="s"
              />
            ) : undefined
          }
          header={
            <div key={sectionKey} id={sectionKey}>
              {section.header}
            </div>
          }
        >
          <SpaceBetween
            children={section.blocks.map((block, blockIdx) => {
              if (block.type === 'p') {
                return (
                  <Box
                    children={block.contents}
                    key={`section-${sectionId}-block-${blockIdx + 1}`}
                    variant="p"
                  />
                );
              } else if (block.type === 'block') {
                return block.contents[0];
              } else if (block.type === 'table' || block.type === 'figure') {
                return block.contents[0];
              } else if (block.type === 'list') {
                return block.contents[0];
              }
              console.error(`Unknown block type: ${block.type}`);

              return null;
            })}
            key={`section-${sectionId}-space`}
            direction="vertical"
            size="s"
          />
        </Container>
      );
    });

    const abstract = (
      <Container
        key="abstract-section"
        header={
          <Header key="abstract-header" variant="h3">
            Abstract
          </Header>
        }
      >
        <Box key="abstract-content" variant="p">
          {this.abstract}
        </Box>
      </Container>
    );

    return [
      abstract,
      ...content,
      <PublicationsList
        key="publications"
        publications={this.citations}
        title="References"
        hideAbstracts
        hideYears
        showNumbers
      />,
    ];
  }
}
