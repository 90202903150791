import { createRef, useState } from 'react';
import { Link } from '@cloudscape-design/components';

import { SearchItem, SearchMessage } from '../../common/chat/types';
import { Modal } from '../Modal';

export type SearchItemsProps = {
  message: SearchMessage;
};

export function SearchItems(props: SearchItemsProps) {
  const { query, items } = props.message;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const maxItems = 2;

  const renderItems = (items: SearchItem[], allItems: SearchItem[]) => {
    return (
      <div className="space-y-2 text-gray-300 px-2 py-2">
        <h2 className="text-base font-bold text-gray-300">Searched {allItems.length} sites</h2>
        <div className="bg-gray-800 rounded-lg border-gray-500 border-2">
          <Link
            href={`https://www.google.com/search?q=${query}`}
            target="_blank"
            variant="secondary"
          >
            <div className="bg-gray-800 rounded-t-lg py-2 px-4 border-gray-500 border-b-2">
              <h4 className="text-base">"{query}"</h4>
              <p className="text-gray-400">google.com</p>
              {/* <p className="text-gray-400">{item.snippet}</p> */}
            </div>
          </Link>
          {items.map((item, idx) => (
            <Link href={item.link} target="_blank">
              <div
                key={item.link}
                className={`bg-gray-800 py-2 px-4 border-gray-500 ${
                  idx === items.length - 1 ? 'rounded-b-lg' : 'border-b-2'
                }`}
              >
                <h4 className="text-base">{item.title}</h4>
                <p className="text-gray-400">{item.display_link}</p>
                {/* <p className="text-gray-400">{item.snippet}</p> */}
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  };

  const scrollRef = createRef<HTMLDivElement>();

  return (
    <div className="space-y-2 text-gray-300">
      {renderItems(items.slice(0, maxItems), items)}
      {items.length > maxItems && (
        <p
          className="text-blue-500 hover:text-blue-300 cursor-pointer mt-2 m-x-auto text-center"
          onClick={() => setIsModalOpen(true)}
        >
          Show all...
        </p>
      )}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div
          ref={scrollRef}
          className="max-h-[80dvh] overflow-y-auto flex flex-col px-4 space-y-2 text-gray-300 pb-4"
        >
          {renderItems(items, items)}
        </div>
      </Modal>
    </div>
  );
}
