import { MessageType, toolCallHandlers, ToolCallName } from './tools';
import { AudioMessage, ChatMessage, LoadingMessage, StreamObject, TextMessage } from './types';

export async function readChatStream(
  reader: ReadableStreamDefaultReader<Uint8Array>,
  setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>,
) {
  const decoder = new TextDecoder('utf-8');

  // TODO may need to check for external conditions to break loop, such as a cancelation
  let runningLine = '';

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { done, value } = await reader.read();

    if (done) {
      break;
    }

    const chunk = decoder.decode(value, { stream: true });
    const lines = chunk
      .split('\n')
      .map((line) => line.trim())
      .filter((line) => line.length > 0);
    // console.log('chunk_lines', lines);

    for (const line of lines) {
      try {
        const parsed = JSON.parse(runningLine + line) as StreamObject;
        runningLine = '';

        // text or audio response completed
        if (parsed.type === 'response.completed' || parsed.type === 'session_ended') {
          break;
        }
        handleStreamObject(parsed, setMessages);
      } catch (e) {
        if (e instanceof SyntaxError) {
          runningLine += line;
        } else {
          console.error(e);
        }
      }
    }
  }

  if (runningLine) {
    try {
      const parsed = JSON.parse(runningLine) as StreamObject;
      handleStreamObject(parsed, setMessages);
    } catch (e) {
      console.info(runningLine);
      console.error(e);
    }
  }
}

export function handleStreamObject(
  obj: StreamObject,
  setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>,
) {
  // console.log('obj', obj);
  // not text or audio delta
  if (obj.type !== 'response.output_text.delta' && obj.type !== 'voice_stream_event_audio') {
    console.log(obj);
  }

  // text or audio response completed
  if (obj.type === 'response.completed' || obj.type === 'session_ended') {
    return;
  }

  setMessages((messages) => {
    const lastMessage = messages[messages.length - 1];

    if (obj.type === 'response.output_text.delta') {
      if (lastMessage.role === 'assistant' && lastMessage.type === MessageType.Text) {
        const updatedMessage = {
          ...lastMessage,
          message: lastMessage.message + obj.delta,
        };

        return [...messages.slice(0, -1), updatedMessage];
      }

      // create new message if last message is not a text message from the assistant
      const newMessage: TextMessage = {
        type: MessageType.Text,
        created_at: new Date().toISOString().split('.')[0],
        role: 'assistant',
        message: obj.delta,
      };

      if (lastMessage.role === 'assistant' && lastMessage.type === MessageType.Loading) {
        return [...messages.slice(0, -1), newMessage];
      }

      return [...messages, newMessage];
    }

    if (obj.type === 'voice_stream_event_audio') {
      // const newMessage: AudioMessage = {
      //   type: MessageType.Audio,
      //   created_at: new Date().toISOString().split('.')[0],
      //   role: 'assistant',
      // };

      if (lastMessage.role === 'assistant' && lastMessage.type === MessageType.Audio) {
        const updatedMessage = {
          ...lastMessage,
          // lastMessage.audio + obj.data if we wanted it all together, but here it is easier to just set to the new chunk
          audio: obj.data,
        };

        return [...messages.slice(0, -1), updatedMessage];
      }

      // create new message if last message is not a text message from the assistant
      const newMessage: AudioMessage = {
        type: MessageType.Audio,
        created_at: new Date().toISOString().split('.')[0],
        role: 'assistant',
        audio: obj.data,
      };

      if (lastMessage.role === 'assistant' && lastMessage.type === MessageType.Loading) {
        return [...messages.slice(0, -1), newMessage];
      }

      return [...messages, newMessage];
    }

    if (obj.type === 'tool.call.created') {
      const newMessage: LoadingMessage = {
        type: MessageType.Loading,
        created_at: new Date().toISOString().split('.')[0],
        role: 'assistant',
        name: obj.name,
      };

      if (lastMessage.role === 'assistant' && lastMessage.type === MessageType.Loading) {
        return [...messages.slice(0, -1), newMessage];
      }

      return [...messages, newMessage];
    }

    if (obj.type === 'tool.call.done') {
      const handler = toolCallHandlers[obj.name as ToolCallName];

      if (handler) {
        const newMessage = handler(obj);

        return [...messages.slice(0, -1), newMessage];
      }
      console.error('Unknown tool call name: ', obj.name);
    }

    if (obj.type === 'tool.call.error') {
      const newMessage: LoadingMessage = {
        type: MessageType.Loading,
        created_at: new Date().toISOString().split('.')[0],
        role: 'assistant',
        name: obj.name,
        error: true,
      };

      return [...messages.slice(0, -1), newMessage];
    }

    return messages;
  });
}
