import { Header, Box, HelpPanel, Icon, Link } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

export function FramesHelp() {
  const navigate = useNavigate();

  return (
    <HelpPanel
      footer={
        <div>
          <Header variant="h3">
            Learn more <Icon name="external" />
          </Header>
          <ul>
            <li>
              <Link
                href="https://ojs.aaai.org/index.php/ICWSM/article/view/19360"
                target="_blank"
                external
              >
                A journey of stance, ontological commitments and moral foundations
              </Link>
            </li>
            <li>
              <Link
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9148945/"
                target="_blank"
                external
              >
                Identifying the framing of beliefs towards vaccine confidence in Twitter discourse
              </Link>
            </li>
          </ul>
        </div>
      }
      header={<Header variant="h2">About Framings</Header>}
    >
      <div>
        <Header variant="h3">What is a framing?</Header>
        <Box variant="p">
          Social science stipulates that discourse almost inescapably involves framing — a strategy
          of highlighting certain issues to promote a certain interpretation or attitude. For
          example, when misinformation is used in framing vaccine confidence, it typically results
          in vaccine hesitancy.
        </Box>
        <Header variant="h3">How were framings discovered?</Header>
        <Box variant="p">
          Framings were discovered utilizing opinion-based Q/A, with questions derived from the{' '}
          <Link href="https://pubmed.ncbi.nlm.nih.gov/30735925/" target="_blank" external>
            Vaccine Confidence Repository
          </Link>
          . See{' '}
          <Link
            href="/frames/questions"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Questions
          </Link>{' '}
          for a full list of questions and for more information on opinion-based Q/A. Framings were
          inferred from the Q/A answers by computational linguistics experts from the{' '}
          <Link href="https://www.hlt.utdallas.edu/" target="_blank" external>
            Human Language Technology Research Institute (HLTRI)
          </Link>
          , informed by the{' '}
          <Link href="https://aclanthology.org/N04-1019/" target="_blank" external>
            pyramid method
          </Link>
          .
        </Box>
        <Header variant="h3">How were framings categorized?</Header>
        <Box variant="p">
          Framings were then categorized as to whether they were{' '}
          <Link
            href="/frames/categories/misinformation"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Misinformation
          </Link>
          ,{' '}
          <Link
            href="/frames/categories/trust-"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Eroding Trust
          </Link>
          ,{' '}
          <Link
            href="/frames/categories/trust+"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Building Trust
          </Link>
          , addressed issues of{' '}
          <Link
            href="/frames/categories/civil_rights"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Civil Rights
          </Link>
          , or involved health{' '}
          <Link
            href="/frames/categories/literacy"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Literacy
          </Link>
          . Additionally, each framing was judged to fall within specific themes for each category.
          See{' '}
          <Link
            href="/frames/categories"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Categories
          </Link>{' '}
          for a full list of categories and for more information on framing categorization.
        </Box>
        <Header variant="h3">How was morality incorporated?</Header>
        <Box variant="p">
          Furthermore, we relied on previous work in social psychology, the{' '}
          <Link href="https://www.jstor.org/stable/20027945" target="_blank" external>
            Moral Foundations Theory
          </Link>
          , as a theoretical framework for analyzing moral framing, using the same five key values
          of human morality, emerging from evolutionary, social, and cultural origins. Each frame
          was annotated with the Moral Foundations of{' '}
          <Link
            href="/frames/moralities/Care"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Care
          </Link>
          /
          <Link
            href="/frames/moralities/Harm"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Harm
          </Link>
          ,{' '}
          <Link
            href="/frames/moralities/Fairness"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Fairness
          </Link>
          /
          <Link
            href="/frames/moralities/Cheating"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Cheating
          </Link>
          ,{' '}
          <Link
            href="/frames/moralities/Loyalty"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Loyalty
          </Link>
          /
          <Link
            href="/frames/moralities/Betrayal"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Betrayal
          </Link>
          ,{' '}
          <Link
            href="/frames/moralities/Authority"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Authority
          </Link>
          /
          <Link
            href="/frames/moralities/Subversion"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Subversion
          </Link>
          , and{' '}
          <Link
            href="/frames/moralities/Purity"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Purity
          </Link>
          /
          <Link
            href="/frames/moralities/Degradation"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Degradation
          </Link>
          . See{' '}
          <Link
            href="/frames/moralities"
            variant="secondary"
            onFollow={(event) => {
              event.preventDefault();
              navigate(event.detail.href!);
            }}
          >
            Moral Foundations
          </Link>{' '}
          for a full list of moral foundations and for more information on moral foundation
          assignment.
        </Box>
      </div>
    </HelpPanel>
  );
}
