import { Icon, Spinner } from '@cloudscape-design/components';
import { useState, useEffect, useMemo } from 'react';
import { useAudioRecorder } from 'react-audio-voice-recorder';

import { ChatAudioRecorder } from '../../common/chat/audio/recorder';

export type ChatBarProps = {
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  addMessage: (message: string) => Promise<void>;
  loading?: boolean;
  onResize?: () => void;
  addAudio?: (audio: Blob, mime_type: string, sampleRate: number) => Promise<void>;
};

const mimeTypes = ['audio/webm', 'audio/wav', 'audio/mpeg', 'audio/mp4', 'audio/ogg', 'audio/flac'];

export function ChatBar(props: ChatBarProps) {
  const { loading, addMessage, textareaRef, onResize, addAudio } = props;
  const [input, setInput] = useState('');
  const mimeType = useMemo(() => {
    const supportedMimeTypes = mimeTypes.filter((mimeType) =>
      MediaRecorder.isTypeSupported(mimeType),
    );
    console.log(`Supported mime types: ${supportedMimeTypes}`);

    if (supportedMimeTypes.length > 0) {
      return supportedMimeTypes[0];
    }
    console.error('No supported mime type');

    return undefined;
  }, []);

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
      autoGainControl: true,
    },
    (exception) => {
      console.error(exception);
    },
    {
      mimeType,
    },
  );

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset the height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set the height to scroll height

      if (onResize) {
        onResize();
      }
    }
  }, [input, textareaRef, onResize]);

  const enterSend = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !loading) {
      if (e.shiftKey) {
        return;
      }
      e.preventDefault();

      if (!input) {
        return;
      }

      const oldInput = input;
      setInput('');

      try {
        await addMessage(input);
      } catch (err) {
        setInput(oldInput);
      }
    }
  };

  const sendClick = async () => {
    if (!loading) {
      if (!input) {
        return;
      }

      const oldInput = input;
      setInput('');

      try {
        await addMessage(input);
      } catch (err) {
        setInput(oldInput);
      }
    }
  };

  const addAudioElement = async (blob: Blob) => {
    console.log('add');

    if (blob && addAudio && mimeType) {
      console.log('send blob');

      try {
        const audioContext = new AudioContext();
        const sampleRate = audioContext.sampleRate;
        await addAudio(blob, mimeType, sampleRate);
      } catch (err) {
        // console.error(err);
        console.error('Error sending audio, no need to revert input');
      }
    }
  };

  return (
    <div
      className={`input flex rounded-[28px] bg-gray-100 pr-2 py-2 items-end ${
        recorderControls.isRecording ? 'pl-2' : 'pl-4'
      }`}
    >
      {!recorderControls.isRecording && (
        <textarea
          ref={textareaRef}
          className="flex-grow p-2 text-black rounded-l-lg resize-none cursor-text bg-transparent focus:outline-none"
          placeholder="Ask any question about my dissertation..."
          rows={1}
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
          }}
          onKeyDown={enterSend}
        />
      )}
      {addAudio && mimeType && (
        <ChatAudioRecorder
          loading={loading}
          recorderControls={recorderControls}
          showVisualizer
          onRecordingComplete={addAudioElement}
        />
      )}
      {!recorderControls.isRecording && (
        <button
          className={`text-black p-2 rounded-full w-10 h-10 font-bold items-center justify-center flex ml-2 ${
            loading || (!loading && input.trim().length === 0) ? 'bg-gray-100' : 'bg-gray-200'
          }`}
          disabled={loading}
          onClick={sendClick}
        >
          {loading ? (
            <Spinner size="normal" />
          ) : (
            <svg
              className="icon-2xl"
              fill="none"
              height="32"
              viewBox="0 0 32 32"
              width="32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.192 8.906a1.143 1.143 0 0 1 1.616 0l5.143 5.143a1.143 1.143 0 0 1-1.616 1.616l-3.192-3.192v9.813a1.143 1.143 0 0 1-2.286 0v-9.813l-3.192 3.192a1.143 1.143 0 1 1-1.616-1.616z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          )}
        </button>
      )}
    </div>
  );
}
