import {
  Box,
  Container,
  Header,
  SpaceBetween,
  Table,
  ColumnLayout,
  Link,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { taxNames } from '../../common/data/frames';
import { FrameInfo } from '../../common/data/types';
import { StanceDonut } from '../stance/stance';

export type FrameInfoTableProps = {
  info: FrameInfo;
};

export function FrameInfoTable(props: FrameInfoTableProps) {
  const navigate = useNavigate();
  const { info } = props;

  return (
    <Container header={<Header variant="h2">Framing Information</Header>}>
      <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="m">
          <div>
            <Box variant="awsui-key-label">Detailed Framing</Box>
            <Box variant="p">{info.frame.details}</Box>
          </div>
          <div>
            <Box variant="awsui-key-label">Question used to Discover Framing</Box>
            <Box variant="p">
              <Link
                href={`/frames/questions/${info.question.q_id}`}
                variant="secondary"
                onFollow={(event) => {
                  event.preventDefault();
                  navigate(event.detail.href!);
                }}
              >
                {info.question?.q_text}
              </Link>
            </Box>
          </div>
          <div>
            <Box variant="awsui-key-label">Moral Foundations</Box>
            <Box variant="p">
              {info.moralities.length > 0
                ? info.moralities
                    .map<React.ReactNode>((mf) => (
                      <Link
                        key={`${info.frame.f_id}-${mf}`}
                        href={`/frames/moralities/${mf}`}
                        variant="secondary"
                        onFollow={(event) => {
                          event.preventDefault();
                          navigate(event.detail.href!);
                        }}
                      >
                        {mf}
                      </Link>
                    ))
                    .reduce((prev, curr) => [prev, ', ', curr])
                : ''}
            </Box>
          </div>
          <div>
            <Table
              columnDefinitions={[
                {
                  id: 'taxonomy',
                  header: 'Category',
                  cell: (item) => (
                    <Link
                      href={`/frames/categories/${item.taxonomy}`}
                      variant="secondary"
                      onFollow={(event) => {
                        event.preventDefault();
                        navigate(event.detail.href!);
                      }}
                    >
                      {taxNames[item.taxonomy]}
                    </Link>
                  ),
                },
                {
                  id: 'text',
                  header: 'Theme',
                  cell: (item) => item.text,
                },
              ]}
              header={<Box variant="awsui-key-label">Themes</Box>}
              items={info.themes}
              variant="embedded"
              wrapLines
            />
          </div>
        </SpaceBetween>
        <StanceDonut
          max_date={info.max_date}
          total={info.total}
          totalAccept={info.totalAccept}
          totalReject={info.totalReject}
        />
      </ColumnLayout>
    </Container>
  );
}
